import { api } from '@cma/app'
import {
  BrokerQuery,
  BrokerQueryVariables,
  CancelBrokerMutation,
  CancelBrokerMutationVariables
} from '@cma/generated/graphql'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export function getBroker({ id }: BrokerQueryVariables) {
  const query = gql`
    query Broker($id: ID!) {
      broker(id: $id) {
        mlsFeeds
        admin {
          avatar
          city
          email
          id
          mlsCredential {
            code
            name
            password
          }
          mobilePhone
          name
          state
          status
        }
        agents {
          total
          edges {
            node {
              id
              firstName: firstname
              lastName: lastname
              isBrokerAdmin
              status
            }
          }
        }
        id
        inviteCount
        key
        logo
        name
        officeAddress
        phone
        status
      }
    }
  `
  const variables = { id }
  return api<BrokerQuery>({ query, variables })
}

export function useBroker({ id }: BrokerQueryVariables, hasKey = true) {
  return useQuery<BrokerQuery, Error>(['broker', id], () => getBroker({ id }), {
    enabled: !!id && hasKey
  })
}

export function useCancelBroker() {
  const queryClient = useQueryClient()
  return useMutation<
    CancelBrokerMutation,
    Error,
    CancelBrokerMutationVariables
  >(
    (variables) => {
      const query = gql`
        mutation CancelBroker($id: ID!) {
          cancelBrokerAccount(id: $id) {
            errors
          }
        }
      `
      return api({ query, variables })
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(['brokers'])
      }
    }
  )
}
