import { Link, Spinner } from '@cma/common'
import { getAccessTokens } from '@cma/features/auth'
import {
  BillingDestroyAccount,
  BillingOrders,
  BillingSubscription,
  SettingsCard,
  useBilling
} from '@cma/features/settings'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate } from 'react-router-dom'

function BillingOverview() {
  const { data: billing } = useBilling()
  const accessTokens = getAccessTokens()
  const isImpersonating = !!accessTokens.impersonateToken
  const hasOrders = !!(
    (billing?.orders?.recurring.length || 0) +
    (billing?.orders?.oneTime.length || 0)
  )

  if (
    !isImpersonating &&
    billing?.user?.lwaId &&
    !(billing?.user?.isAccountAdmin && billing?.account?.isSmallBroker)
  ) {
    return <Navigate to="/settings" />
  }

  return (
    <SettingsCard>
      <SettingsCard.Group>
        <SettingsCard.Title>Billing</SettingsCard.Title>
        <BillingSubscription />
        {(!billing?.user?.isCasUser && !billing?.account?.isSmallBroker) ||
        (billing?.user?.isAccountAdmin && billing?.account?.isSmallBroker) ? (
          <p className="text-center text-sm">
            <Link to="/settings/billing/payments" className="helix-text-brand">
              Edit my credit card and plan
            </Link>
          </p>
        ) : billing?.user?.isCasUser ? (
          <p
            className="text-center text-sm text-gray-500"
            data-testid="cas-subscription">
            Visit{' '}
            <a
              href={`${process.env.VITE_APP_CAS_URL}/app/billing`}
              target="_blank"
              rel="noreferrer"
              className="helix-text-brand">
              Cloud Agent Suite
            </a>{' '}
            to manage your Cloud CMA subscription.
          </p>
        ) : null}
      </SettingsCard.Group>
      <SettingsCard.Group>
        <SettingsCard.Title>Cancel</SettingsCard.Title>
        <BillingDestroyAccount />
      </SettingsCard.Group>
      {hasOrders && (
        <SettingsCard.Group>
          <SettingsCard.Title>Orders</SettingsCard.Title>
          <BillingOrders />
          {billing?.user?.isAccountAdmin && (
            <p className="helix-text-brand mt-4 text-center text-sm">
              <a
                href={`/users/${billing?.user?.id}/receipt`}
                target="_blank"
                rel="noreferrer"
                className="helix-text-brand hover:no-underline">
                View receipt for payments
              </a>
            </p>
          )}
        </SettingsCard.Group>
      )}
    </SettingsCard>
  )
}

export default function BillingOverviewProvider() {
  return (
    <>
      <Helmet>
        <title>Overview - Billing - Account Settings - Cloud CMA</title>
      </Helmet>
      <Suspense
        fallback={
          <SettingsCard>
            <SettingsCard.Group>
              <SettingsCard.Title>Billing</SettingsCard.Title>
              <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                <div className="h-4 w-4">
                  <Spinner />
                </div>
                <div>Loading Billing Information</div>
              </div>
            </SettingsCard.Group>
          </SettingsCard>
        }>
        <BillingOverview />
      </Suspense>
    </>
  )
}
