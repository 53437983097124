import { useCurrentUser } from '@cma/app'
import {
  copyTextToClipboard,
  FormField,
  Modal,
  Select,
  Spinner,
  useTimedState
} from '@cma/common'
import { CopyIcon } from '@cma/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useReportStatus, useReportTemplates } from '../report'
import { usePublishReport } from '../report/usePublishReport'
import { useTour } from './useTour'

interface TourShareProps {
  tourId: string
  isOpen: boolean
  onClose: () => void
}

const pdfSchema = yup.object({
  template: yup.string()
})

export function TourShare({ tourId, isOpen, onClose }: TourShareProps) {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: { tour } = {} } = useTour({ id: tourId })
  const { data: { reportTemplates } = {} } = useReportTemplates(tourId)
  const [copied, startCopyTimer] = useTimedState()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof pdfSchema>>({
    resolver: yupResolver(pdfSchema),
    defaultValues: {
      template: tour?.pageTemplate ?? ''
    }
  })
  const { mutate: publishReport, isLoading } = usePublishReport()
  const isPublishing = tour?.status === 'active-report'
  const isPublished = tour?.status === 'published-report'

  useReportStatus({ id: tourId, status: tour?.status || 'pending-report' })

  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Share</Modal.Title>
      <div className="mt-3 space-y-6">
        <div className="space-y-3">
          <button
            className="helix-btn helix-btn--secondary flex w-full items-center justify-center gap-2"
            disabled={copied}
            onClick={() => {
              copyTextToClipboard(window.location.href)
              startCopyTimer()
            }}>
            <CopyIcon className="h-5 w-5" />
            {copied ? 'Copied public link!' : 'Copy public link'}
          </button>
        </div>
        {currentUser?.id === tour?.agent?.id && (
          <div className="space-y-3">
            <div>Print</div>
            <form
              className="space-y-2"
              onSubmit={handleSubmit((data) =>
                publishReport({ id: tourId, template: data.template })
              )}>
              <div className="flex items-end gap-2">
                <div className="grow">
                  <FormField label="Template" error={errors.template?.message}>
                    <Select
                      fullWidth
                      {...register('template')}
                      disabled={!reportTemplates?.pageTemplates?.length}>
                      <option value="" disabled>
                        Select an Option
                      </option>
                      {reportTemplates?.pageTemplates?.map((template) => (
                        <option key={template.id} value={template.id || ''}>
                          {template.title}
                        </option>
                      ))}
                    </Select>
                  </FormField>
                </div>
                <a
                  data-pendo-id="edit-tour-pdf-template-button"
                  className="helix-btn helix-btn--secondary flex w-full items-center justify-center gap-2 hover:no-underline"
                  href={`${process.env.VITE_APP_CMA_URL}/tours/${tourId}/customize`}>
                  Customize
                </a>
              </div>
              <button
                data-pendo-id="generate-tour-pdf-button"
                className="helix-btn helix-btn--secondary flex w-full items-center justify-center gap-2"
                disabled={isLoading || isPublishing}>
                {(isLoading || isPublishing) && (
                  <span className="h-5 w-5 flex-shrink-0">
                    <Spinner />
                  </span>
                )}
                Generate PDF
              </button>
            </form>
          </div>
        )}
        {!isLoading && isPublished && tour?.pdfPermalink && (
          <div className="flex justify-center">
            <a
              href={tour?.pdfPermalink || ''}
              target="_blank"
              rel="noreferrer"
              className="text-sm text-gray-500">
              View PDF
            </a>
          </div>
        )}
      </div>
    </Modal>
  )
}
