import { classNames } from '@cma/common'
import { useState } from 'react'
import { CustomizeMyRecentSales } from './CustomizeMyRecentSales'
import { CustomizePageContent } from './CustomizePageContent'
import { CustomizePageTitle } from './CustomizePageTitle'
import { CustomizePageWithPages } from './useCustomizePages'

type EditableType = 'content' | 'title' | 'recent_sales'

export function getEditType(
  page: CustomizePageWithPages
): EditableType | undefined {
  if (page.isCustom) {
    return 'content'
  }

  switch (page.key) {
    case 'cover_letter':
    case 'agent_resume':
    case 'our_company':
      return 'content'
    case 'cover':
      return 'title'
    case 'my_recent_sales':
      return 'recent_sales'
    default:
      return undefined
  }
}

interface CustomizePageEditProps {
  page: CustomizePageWithPages
}

export function CustomizePageEdit({ page }: CustomizePageEditProps) {
  const [showEdit, setShowEdit] = useState(false)
  const editType = getEditType(page)

  if (!editType || (!page.active && editType === 'title')) {
    return null
  }

  return (
    <>
      {editType === 'title' && (
        <CustomizePageTitle
          page={page}
          isOpen={showEdit}
          onClose={() => setShowEdit(false)}
        />
      )}
      <button
        type="button"
        aria-label={`Edit ${page.title}`}
        className={classNames('helix-text-brand', {
          'text-xs': !page.active,
          'text-sm font-medium': page.active
        })}
        onClick={() => setShowEdit(true)}>
        Edit
      </button>
      {editType === 'content' && (
        <CustomizePageContent
          page={page}
          isOpen={showEdit}
          onClose={() => setShowEdit(false)}
        />
      )}
      {editType === 'recent_sales' && (
        <CustomizeMyRecentSales
          isOpen={showEdit}
          onClose={() => setShowEdit(false)}
        />
      )}
    </>
  )
}
