import { api } from '@cma/app'
import { CurrentUserQuery } from '@cma/generated/graphql'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

export enum UserRole {
  PARTNER_ADMIN = 990,
  MLS_ADMIN = 999,
  BROKER_ADMIN = 9990,
  ADMIN = 9999,
  ADMIN_PLUS = 99990,
  SUPER_ADMIN = 99999
}

export function useCurrentUser() {
  return useQuery<CurrentUserQuery, Error>(
    ['currentUser'],
    () => {
      const query = gql`
        query CurrentUser {
          currentUser {
            id
            name
            email
            avatar
            guid
            companyName
            companyLogoUrl
            companyLogoName
            city
            state
            status
            casUserId
            isCasUser
            isAdmin
            passwordSetAt
            liveToursEnabled
            # TODO: Implement this on the backend
            # isSparkUser
            isAuthSso
            role
            zipFormToken
            transactionDeskToken
            subscriptionPlatform
            platformUid
            autoSendWebleadEmail
            mobilePhone
            officeAddress
            officePhone
            isAccountAdmin
            lwaId
            lwaJwt
            casJwt
            mlsCredential {
              code
              name
              password
            }
            features {
              canEditMls
              canEditRemarks
              hasAddressAutocomplete
              hasApiButton
              hasDocuments
              hasFlyers
              hasHomebeat
              hasIbuyerConnect
              hasLive
              hasLiveTours
              hasPresent
              hasTransactions
              offerHomebeat
              hasBrokerAccess
              offerRdcToolkit
              hasRdcToolkit
            }
            account {
              name
              id
              key
              admin {
                email
                companyLogoUrl
              }
              isSmallBroker
            }
          }
        }
      `
      return api<CurrentUserQuery>({ query })
    },
    {
      retry: false
    }
  )
}
