import { useCurrentUser, UserRole } from '@cma/app'
import {
  Alert,
  Avatar,
  Badge,
  Button,
  classNames,
  formatDate,
  formatNumber,
  getTimeAgo,
  Input,
  Menu,
  NotFound,
  Pagination,
  Spinner,
  Table,
  Tabs,
  toast,
  Tooltip,
  usePaginate,
  Wrapper
} from '@cma/common'
import {
  AddAgent,
  BrokerStats,
  DeleteAccount,
  ImportAgents,
  prettyStatus,
  StatusFilter,
  useBroker,
  useBrokerAgents,
  useDestroyBrokerAgent,
  useInviteBroker,
  useInviteBrokerAgents
} from '@cma/features/admin/broker'
import { getAccessTokens } from '@cma/features/auth'
import {
  AgentFilterFieldEnum,
  AgentSortEnum,
  BrokerAgentPartsFragment
} from '@cma/generated/graphql'
import {
  ArrowSmallDownIcon,
  CsvIcon,
  DownloadIcon,
  InfoIcon,
  SearchIcon,
  ShortcutIcon
} from '@cma/icons'
import {
  ExclamationCircleIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/outline'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import pluralize from 'pluralize'
import {
  ChangeEvent,
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import Highlighter from 'react-highlight-words'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'

interface IBrokerContext {
  status: StatusFilter
  search: string
  sortKey?: AgentSortEnum
  setSortKey: Dispatch<SetStateAction<AgentSortEnum | undefined>>
  paginate: ReturnType<typeof usePaginate>
  setStatusFilter: (newFilter: StatusFilter) => void
}

const BrokerContext = createContext<IBrokerContext>({
  status: StatusFilter.all,
  search: '',
  sortKey: undefined,
  setSortKey() {},
  paginate: {
    after: undefined,
    before: undefined,
    first: undefined,
    last: undefined,
    limit: 10,
    search: undefined,
    setAfter() {},
    setBefore() {},
    setFirst() {},
    setLast() {},
    setSearch() {}
  },
  setStatusFilter() {}
})

function useBrokerId() {
  const { brokerId } = useParams()
  const { data: { currentUser } = {} } = useCurrentUser()
  return brokerId || currentUser?.account?.id
}

export default function Broker() {
  const navigate = useNavigate()
  const brokerId = useBrokerId()
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: { broker } = {} } = useBroker({ id: brokerId as string })
  const { mutate: inviteBroker, isLoading: isInviting } = useInviteBroker()
  const { mutate: inviteAgents } = useInviteBrokerAgents()
  const [isShowingImportModal, setIsShowingImportModal] = useState(false)
  const [isShowingAddAgentModal, setIsShowingAddAgentModal] = useState(false)
  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [sortKey, setSortKey] = useState<AgentSortEnum>()
  const [statusFilter, setStatusFilter] = useState<StatusFilter>(
    StatusFilter.all
  )
  const showAdminControls = (currentUser?.role || 0) >= UserRole.ADMIN
  const tokens = getAccessTokens()

  const isFocusedRef = useRef(false)
  useEffect(() => {
    function onKeyPress(event: KeyboardEvent) {
      if (event.key === '/' && !isFocusedRef.current) {
        event.preventDefault()
        inputRef.current?.focus()
      }
    }
    document.body.addEventListener('keydown', onKeyPress)
    return () => {
      document.body.removeEventListener('keydown', onKeyPress)
    }
  }, [])

  const paginate = usePaginate({ limit: 10 })
  const context: IBrokerContext = {
    status: statusFilter,
    paginate,
    sortKey,
    setSortKey,
    search: debouncedSearch,
    setStatusFilter
  }

  if (!broker) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <BrokerStats brokerId={broker.id} />
      <header className="flex items-baseline justify-between space-x-2">
        <h1 className="text-4xl font-medium">Roster</h1>
        <div className="flex items-center space-x-2">
          {showAdminControls && (
            <Button
              outline
              variant="danger"
              leftIcon={<ExclamationCircleIcon />}
              onClick={() => setIsShowingDeleteModal(true)}>
              Delete Account
            </Button>
          )}
          <Button
            outline
            leftIcon={<DownloadIcon />}
            onClick={() => setIsShowingImportModal(true)}>
            Import Agents
          </Button>
          <Button
            leftIcon={<PlusIcon />}
            onClick={() => setIsShowingAddAgentModal(true)}>
            Add Agent
          </Button>
          <Menu
            button={
              <Button as="span">
                <DotsHorizontalIcon className="h-5 w-5" />
              </Button>
            }>
            <Menu.Item
              as="a"
              icon={<CsvIcon />}
              href={`${process.env.VITE_APP_CMA_URL}/admin/user_download_account/${brokerId}?jwt=${tokens?.currentToken}&broker_agent_roaster=true`}
              download>
              Download roster
            </Menu.Item>
            <Menu.Item
              as="a"
              icon={<CsvIcon />}
              href={`${process.env.VITE_APP_CMA_URL}/admin/user_download_account_report_counts/${brokerId}?jwt=${tokens?.currentToken}&broker_agent_roaster=true`}
              download>
              Download usage report
            </Menu.Item>
          </Menu>
          <ImportAgents
            brokerId={broker.id}
            brokerName={broker.name}
            canSendInvite={broker.admin?.status === 'complete'}
            isOpen={isShowingImportModal}
            onClose={() => setIsShowingImportModal(false)}
          />
          <AddAgent
            brokerId={broker.id}
            brokerName={broker.name}
            canSendInvite={broker.admin?.status === 'complete'}
            isOpen={isShowingAddAgentModal}
            onClose={() => setIsShowingAddAgentModal(false)}
          />
          <DeleteAccount
            id={broker.id}
            name={broker.name}
            isOpen={isShowingDeleteModal}
            onClose={() => setIsShowingDeleteModal(false)}
            onSuccess={() => navigate('/admin/brokers')}
          />
        </div>
      </header>
      <div className="space-y-4">
        <Tabs
          selectedIndex={statusFilter}
          onChange={(status) => setStatusFilter(status)}>
          <div className="flex items-center justify-between space-x-2">
            <Tabs.List>
              <Tabs.Tab>All</Tabs.Tab>
              <Tabs.Tab>Not Setup</Tabs.Tab>
              <Tabs.Tab>Invited</Tabs.Tab>
              <Tabs.Tab>Onboarding</Tabs.Tab>
              <Tabs.Tab>Complete</Tabs.Tab>
            </Tabs.List>
            <div className="w-full max-w-[29%]">
              <Input
                ref={inputRef}
                aria-label="search"
                leftIcon={<SearchIcon />}
                rightIcon={<ShortcutIcon className="text-gray-700" />}
                placeholder="Search agent name or email"
                value={search}
                onFocus={() => (isFocusedRef.current = true)}
                onBlur={() => (isFocusedRef.current = false)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  paginate.setBefore(undefined)
                  paginate.setAfter(undefined)
                  paginate.setFirst(paginate.limit)
                  paginate.setLast(undefined)
                  setSearch(e.target.value)
                }}
              />
            </div>
          </div>
          {broker?.status === 'notSetup' && (
            <div
              data-testid="email-admin-banner"
              className="flex w-full flex-row items-center justify-center space-x-2 rounded-lg bg-gray-50 p-4 text-sm text-blue-900 shadow-sticky">
              <span>
                <InfoIcon className="mr-1 h-6 w-6 text-blue-600" />
              </span>
              <span>
                Send invitation email to {broker.admin?.name} (Broker admin)
              </span>
              <button
                className="font-bold underline underline-offset-2"
                disabled={isInviting}
                onClick={() => {
                  inviteBroker(
                    { accountId: broker.id },
                    {
                      onSuccess: () => {
                        toast(
                          <>
                            <strong>{broker.admin?.name}</strong> has been
                            invited successfully.
                          </>,
                          { variant: 'success' }
                        )
                      },
                      onError: (error) => {
                        toast(
                          <>
                            <strong>An error has occurred:</strong>{' '}
                            {error.message}
                          </>,
                          { variant: 'error' }
                        )
                      }
                    }
                  )
                }}>
                Send Invite &rarr;
              </button>
            </div>
          )}
          {broker.admin?.status === 'complete' && broker.agents.total <= 0 && (
            <div
              data-testid="import-agents-banner"
              className="flex w-full flex-row items-center justify-center space-x-2 rounded-lg bg-gray-50 p-4 text-sm text-blue-900 shadow-sticky">
              <span>
                <InfoIcon className="mr-1 h-6 w-6 text-blue-600" />
              </span>
              <span>Looks like you're the only one in this roster.</span>
              <button
                className="font-bold underline underline-offset-2"
                disabled={isInviting}
                onClick={() => setIsShowingImportModal(true)}>
                Import Agents &rarr;
              </button>
            </div>
          )}
          {/* TODO: Write a test for this */}
          {broker.admin?.status === 'complete' &&
            broker.agents.total > 0 &&
            (broker.inviteCount || 0) > 0 && (
              <div
                data-testid="invite-remaining-agents-banner"
                className="flex w-full flex-row items-center justify-center space-x-2 rounded-lg bg-gray-50 p-4 text-sm text-blue-900 shadow-sticky">
                <span>
                  <InfoIcon className="mr-1 h-6 w-6 text-blue-600" />
                </span>
                <span>
                  Invite your remaining{' '}
                  {pluralize('agents', broker.inviteCount || 0)} (
                  {broker.inviteCount})
                </span>
                <button
                  className="font-bold underline underline-offset-2"
                  disabled={isInviting}
                  onClick={() => {
                    if (broker?.id) {
                      inviteAgents(
                        { accountId: broker.id, agentIds: [] },
                        {
                          onSuccess: () => {
                            toast(
                              <>
                                {pluralize(
                                  'agents',
                                  broker.inviteCount || 0,
                                  true
                                )}{' '}
                                has been invited successfully.
                              </>,
                              {
                                variant: 'success'
                              }
                            )
                          },
                          onError: (error) => {
                            toast(
                              <>
                                <strong>An error has occurred:</strong>{' '}
                                {error.message}
                              </>,
                              { variant: 'error' }
                            )
                          }
                        }
                      )
                    }
                  }}>
                  Send Invites &rarr;
                </button>
              </div>
            )}
          <Tabs.Panels>
            <BrokerContext.Provider
              value={{ ...context, status: StatusFilter.all }}>
              <Tabs.Panel>
                <Suspense fallback={<SkeletonTable />}>
                  <AgentTable />
                </Suspense>
              </Tabs.Panel>
            </BrokerContext.Provider>
            <BrokerContext.Provider
              value={{ ...context, status: StatusFilter.notSetup }}>
              <Tabs.Panel>
                <Suspense fallback={<SkeletonTable />}>
                  <AgentTable />
                </Suspense>
              </Tabs.Panel>
            </BrokerContext.Provider>
            <BrokerContext.Provider
              value={{ ...context, status: StatusFilter.invited }}>
              <Tabs.Panel>
                <Suspense fallback={<SkeletonTable />}>
                  <AgentTable />
                </Suspense>
              </Tabs.Panel>
            </BrokerContext.Provider>
            <BrokerContext.Provider
              value={{ ...context, status: StatusFilter.onboarding }}>
              <Tabs.Panel>
                <Suspense fallback={<SkeletonTable />}>
                  <AgentTable />
                </Suspense>
              </Tabs.Panel>
            </BrokerContext.Provider>
            <BrokerContext.Provider
              value={{ ...context, status: StatusFilter.complete }}>
              <Tabs.Panel>
                <Suspense fallback={<SkeletonTable />}>
                  <AgentTable />
                </Suspense>
              </Tabs.Panel>
            </BrokerContext.Provider>
          </Tabs.Panels>
        </Tabs>
      </div>
    </Wrapper>
  )
}

function AgentTable() {
  const brokerId = useBrokerId()
  const { status, search, paginate, sortKey, setSortKey } =
    useContext(BrokerContext)
  const { data: { broker } = {} } = useBrokerAgents({
    id: brokerId as string,
    first: paginate.first,
    last: paginate.last,
    after: paginate.after,
    before: paginate.before,
    sortKey,
    filters: [
      { field: AgentFilterFieldEnum.Status, value: StatusFilter[status] },
      { field: AgentFilterFieldEnum.Name, value: search }
    ]
  })
  const agents = broker?.agents

  return (
    <div className="space-y-4">
      <div className="space-y-2 overflow-auto rounded-lg bg-white shadow print:border print:shadow-none">
        <Table>
          <Table.Head>
            <Table.Row>
              <TableHeader
                isSortActive={sortKey === AgentSortEnum.Name}
                sortKey={AgentSortEnum.Name}
                onClick={() => {
                  setSortKey(
                    sortKey === AgentSortEnum.Name
                      ? undefined
                      : AgentSortEnum.Name
                  )
                }}>
                Agents
              </TableHeader>
              <TableHeader align="right">Reports</TableHeader>
              <TableHeader
                isSortActive={sortKey === AgentSortEnum.UpdatedAt}
                sortKey={AgentSortEnum.UpdatedAt}
                onClick={() => {
                  setSortKey(
                    sortKey === AgentSortEnum.UpdatedAt
                      ? undefined
                      : AgentSortEnum.UpdatedAt
                  )
                }}>
                Latest Report
              </TableHeader>
              <TableHeader>Last Login</TableHeader>
              <TableHeader>
                <span className="sr-only">Actions</span>
              </TableHeader>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {!!search && !agents?.edges?.length && (
              <Table.Row>
                <Table.Data colSpan={5}>
                  <NoResults />
                  {status !== StatusFilter.all && <SearchAll />}
                </Table.Data>
              </Table.Row>
            )}
            {!search && !agents?.edges?.length && (
              <Table.Row>
                <Table.Data>
                  <div>0 Agents Found</div>
                </Table.Data>
              </Table.Row>
            )}
            {agents?.edges?.map((edge) => {
              if (edge?.node) {
                return <AgentRow key={edge.node.id} agent={edge.node} />
              }
            })}
          </Table.Body>
        </Table>
      </div>
      <Pagination
        key={search}
        total={agents?.total}
        limit={paginate.limit}
        disablePrev={!agents?.pageInfo.hasPreviousPage}
        disableNext={!agents?.pageInfo.hasNextPage}
        onPrevClick={() => {
          paginate.setBefore(agents?.pageInfo.startCursor ?? undefined)
          paginate.setAfter(undefined)
          paginate.setFirst(undefined)
          paginate.setLast(paginate.limit)
        }}
        onNextClick={() => {
          paginate.setBefore(undefined)
          paginate.setAfter(agents?.pageInfo?.endCursor ?? undefined)
          paginate.setFirst(paginate.limit)
          paginate.setLast(undefined)
        }}
      />
    </div>
  )
}

interface AgentRowProps {
  agent: BrokerAgentPartsFragment
}

function AgentRow({ agent }: AgentRowProps) {
  const brokerId = useBrokerId()
  const { search } = useContext(BrokerContext)
  const [showDelete, setShowDelete] = useState(false)
  const {
    mutate: destroyAgent,
    isLoading: isDestroying,
    error: destroyError
  } = useDestroyBrokerAgent()
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: { broker } = {} } = useBroker({ id: brokerId as string })
  const { mutate: inviteBroker, isLoading: isInvitingOrReInvitingBroker } =
    useInviteBroker()
  const { mutate: inviteAgents, isLoading: isInvitingOrReInvitingAgent } =
    useInviteBrokerAgents()
  const currentRole = currentUser?.role || 0
  const showControls = currentRole >= UserRole.BROKER_ADMIN
  const agentName = [agent.firstname, agent.lastname].filter(Boolean).join(' ')
  const liveUrl =
    agent.recentReport?.guid &&
    currentUser?.features?.hasLive &&
    agent.recentReport.__typename === 'CmaReport'
      ? `${process.env.VITE_APP_CMA_URL}/live/${agent.recentReport.guid}`
      : ''

  return (
    <Table.Row>
      <Table.Data>
        <div className="flex items-center space-x-3">
          <Avatar src={agent.avatar || undefined} name={agentName} size="sm" />
          <div className="space-y-0.5">
            <div className="flex items-center space-x-2">
              <div
                data-testid="agent-name"
                className={classNames({
                  'text-gray-900': !search,
                  'font-normal text-gray-700': !!search
                })}>
                <Highlighter
                  autoEscape
                  searchWords={search.split(' ')}
                  textToHighlight={agentName}
                  highlightClassName="font-semibold bg-transparent"
                />
              </div>
              {agent.isBrokerAdmin && <Badge>Admin</Badge>}
            </div>
            <a
              href={`mailto:${agent.email}`}
              className="text-xs text-gray-500"
              data-testid="agent-email">
              <Highlighter
                autoEscape
                searchWords={search.split(' ')}
                textToHighlight={agent.email}
                highlightClassName="font-semibold bg-transparent"
              />
            </a>
          </div>
        </div>
      </Table.Data>
      <Table.Data align="right">
        {agent.reportCount ? formatNumber(agent.reportCount) : '-'}
      </Table.Data>
      <Table.Data>
        {!agent.recentReport && '-'}
        {agent.recentReport && (
          <div className="space-y-0.5">
            <div className="font-normal">{agent.recentReport.title}</div>
            <div className="flex space-x-4 text-xs font-normal">
              <a
                href={agent.recentReport.pdfPermalink || undefined}
                target="_blank"
                rel="noreferrer"
                className={classNames('helix-text-brand', {
                  'cursor-not-allowed opacity-50':
                    !agent.recentReport.pdfPermalink
                })}>
                View PDF
              </a>
              <a
                href={liveUrl || undefined}
                target="_blank"
                rel="noreferrer"
                className={classNames('helix-text-brand', {
                  'cursor-not-allowed opacity-50': !liveUrl
                })}>
                View Live
              </a>
              {agent.recentReport.updatedAt && (
                <span className="text-gray-500">
                  {formatDate(agent.recentReport.updatedAt, 'MM/dd/yyyy')}
                </span>
              )}
            </div>
          </div>
        )}
      </Table.Data>
      <Table.Data>
        {agent.status === 'complete' && agent.lastLoggedIn && (
          <Badge>{getTimeAgo(new Date(agent.lastLoggedIn))}</Badge>
        )}
        {agent.status === 'invited' && <Badge variant="warning">Invited</Badge>}
        {agent.status === 'notSetup' && (
          <Badge variant="danger">Not Setup</Badge>
        )}
        {agent.status === 'onboarding' && (
          <Badge variant="info">Onboarding</Badge>
        )}
      </Table.Data>
      <Table.Data align="right">
        {showControls && (
          <div className="flex items-center justify-end space-x-4">
            {(agent.isBrokerAdmin || broker?.admin?.status === 'complete') &&
              agent.status === 'invited' && (
                <button
                  disabled={
                    isInvitingOrReInvitingBroker || isInvitingOrReInvitingAgent
                  }
                  className="helix-text-brand text-sm"
                  onClick={() => {
                    if (broker?.id) {
                      if (agent.isBrokerAdmin && agent.status !== 'invited') {
                        inviteBroker(
                          { accountId: broker.id },
                          {
                            onSuccess: () => {
                              toast(
                                <>
                                  <strong>{broker.admin?.name}</strong> has been
                                  invited successfully.
                                </>,
                                { variant: 'success' }
                              )
                            },
                            onError: (error) => {
                              toast(
                                <>
                                  <strong>An error has occurred:</strong>{' '}
                                  {error.message}
                                </>,
                                { variant: 'error' }
                              )
                            }
                          }
                        )
                      } else {
                        inviteAgents(
                          { accountId: broker.id, agentIds: [agent.id] },
                          {
                            onSuccess: () => {
                              toast(
                                <>
                                  <strong>{agentName}</strong> has been invited
                                  successfully.
                                </>,
                                { variant: 'success' }
                              )
                            },
                            onError: (error) => {
                              toast(
                                <>
                                  <strong>An error has occurred:</strong>{' '}
                                  {error.message}
                                </>,
                                { variant: 'error' }
                              )
                            }
                          }
                        )
                      }
                    }
                  }}>
                  {!isInvitingOrReInvitingBroker &&
                    !isInvitingOrReInvitingAgent &&
                    'Resend'}
                  {(isInvitingOrReInvitingBroker ||
                    isInvitingOrReInvitingAgent) && (
                    <div className="h-5 w-5 text-gray-500 opacity-50">
                      <Spinner />
                    </div>
                  )}
                </button>
              )}
            {!agent.isBrokerAdmin &&
              broker?.admin?.status !== 'complete' &&
              agent.status === 'invited' && (
                <Tooltip content="You must onboard your broker admin first.">
                  <div>
                    <button
                      disabled
                      className="helix-text-brand text-sm opacity-50">
                      Resend
                    </button>
                  </div>
                </Tooltip>
              )}
            {(agent.isBrokerAdmin || broker?.admin?.status === 'complete') &&
              agent.status === 'notSetup' && (
                <button
                  disabled={
                    isInvitingOrReInvitingBroker || isInvitingOrReInvitingAgent
                  }
                  className="helix-text-brand text-sm"
                  onClick={() => {
                    if (broker?.id) {
                      if (agent.isBrokerAdmin) {
                        inviteBroker(
                          { accountId: broker.id },
                          {
                            onSuccess: () => {
                              toast(
                                <>
                                  <strong>{broker.admin?.name}</strong> has been
                                  invited successfully.
                                </>,
                                { variant: 'success' }
                              )
                            },
                            onError: (error) => {
                              toast(
                                <>
                                  <strong>An error has occurred:</strong>{' '}
                                  {error.message}
                                </>,
                                { variant: 'error' }
                              )
                            }
                          }
                        )
                      } else {
                        inviteAgents(
                          { accountId: broker.id, agentIds: [agent.id] },
                          {
                            onSuccess: () => {
                              toast(
                                <>
                                  <strong>{agentName}</strong> has been invited
                                  successfully.
                                </>,
                                { variant: 'success' }
                              )
                            },
                            onError: (error) => {
                              toast(
                                <>
                                  <strong>An error has occurred:</strong>{' '}
                                  {error.message}
                                </>,
                                { variant: 'error' }
                              )
                            }
                          }
                        )
                      }
                    }
                  }}>
                  {!isInvitingOrReInvitingBroker &&
                    !isInvitingOrReInvitingAgent &&
                    'Invite'}
                  {(isInvitingOrReInvitingBroker ||
                    isInvitingOrReInvitingAgent) && (
                    <div className="h-5 w-5 text-gray-500 opacity-50">
                      <Spinner />
                    </div>
                  )}
                </button>
              )}
            {!agent.isBrokerAdmin &&
              broker?.admin?.status !== 'complete' &&
              agent.status === 'notSetup' && (
                <Tooltip content="You must onboard your broker admin first.">
                  <div>
                    <button
                      disabled
                      className="helix-text-brand text-sm opacity-50">
                      Invite
                    </button>
                  </div>
                </Tooltip>
              )}
            {(agent.status === 'onboarding' ||
              agent.status === 'complete' ||
              showControls) && (
              <a
                href={`${process.env.VITE_APP_CMA_URL}/switch_user/remember_user?scope_identifier=user_${agent.id}&remember=true`}
                className="helix-text-brand text-sm hover:no-underline">
                Login As
              </a>
            )}
            {!agent.isBrokerAdmin && (
              <button
                className="flex h-7 w-9 items-center justify-center rounded text-gray-500 hover:bg-black-alpha-50"
                aria-label="Delete Agent"
                onClick={() => setShowDelete(true)}>
                <TrashIcon className="h-5 w-5" />
              </button>
            )}
            {agent.isBrokerAdmin && <div className="h-7 w-9" />}
            <Alert variant="danger" isOpen={showDelete}>
              <Alert.Title>Delete Agent</Alert.Title>
              <Alert.Content>
                <div className="space-y-2">
                  <div>
                    Are you sure you want to delete this agent (
                    <strong className="font-medium text-gray-700">
                      {agentName}
                    </strong>
                    )? This action cannot be undone.
                  </div>
                  {destroyError && (
                    <p className="text-red-500" role="alert" aria-live="polite">
                      {destroyError.message}
                    </p>
                  )}
                </div>
              </Alert.Content>
              <Alert.Cancel onClick={() => setShowDelete(false)}>
                Cancel
              </Alert.Cancel>
              <Alert.Confirm
                loading={isDestroying}
                onClick={() => {
                  if (brokerId) destroyAgent({ id: agent.id })
                }}>
                Delete Forever
              </Alert.Confirm>
            </Alert>
          </div>
        )}
      </Table.Data>
    </Table.Row>
  )
}

function NoResults() {
  const { status, search } = useContext(BrokerContext)

  return (
    <div className="flex justify-center">
      <p className="p-4 text-lg">
        There are no results for <span className="font-bold">"{search}"</span>{' '}
        in <span className="font-bold">{prettyStatus(status)}</span> broker
        accounts.
      </p>
    </div>
  )
}

function SearchAll() {
  const { setStatusFilter } = useContext(BrokerContext)

  return (
    <div className="flex justify-center">
      <Button size="lg" onClick={() => setStatusFilter(0)}>
        Search All instead?
      </Button>
    </div>
  )
}

interface TableHeaderProps {
  align?: 'left' | 'center' | 'right'
  isSortActive?: boolean
  sortKey?: AgentSortEnum
  onClick?: () => void
}

function TableHeader({
  align,
  children,
  isSortActive,
  sortKey,
  onClick
}: PropsWithChildren<TableHeaderProps>) {
  return (
    <Table.Header align={align} onClick={onClick}>
      <div
        className={classNames('flex items-center space-x-2', {
          'justify-left': align === 'left',
          'justify-center': align === 'center',
          'justify-end': align === 'right',
          'cursor-pointer': !!onClick
        })}>
        <div>{children}</div>
        {isSortActive && (
          <>
            {sortKey === AgentSortEnum.Name && (
              <ArrowSmallDownIcon className="helix-text-brand h-4 w-4" />
            )}
            {sortKey === AgentSortEnum.UpdatedAt && (
              <ArrowSmallDownIcon className="helix-text-brand h-4 w-4" />
            )}
          </>
        )}
      </div>
    </Table.Header>
  )
}

function SkeletonTable() {
  return (
    <div className="space-y-2 overflow-auto rounded-lg bg-white shadow print:border print:shadow-none">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>Agents</Table.Header>
            <Table.Header align="right">Reports</Table.Header>
            <Table.Header>Latest Report</Table.Header>
            <Table.Header>Last Login</Table.Header>
            <Table.Header>
              <span className="sr-only">Actions</span>
            </Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Data colSpan={5}>
              <div className="flex items-center space-x-2 text-gray-500">
                <div className="h-5 w-5">
                  <Spinner />
                </div>
                <div>Loading Agents</div>
              </div>
            </Table.Data>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}
