import { useCurrentUser, useUpdateUserConfig } from '@cma/app'
import {
  Button,
  Checkbox,
  copyTextToClipboard,
  Input,
  Modal,
  Wrapper
} from '@cma/common'
import { useBoost } from '@cma/features/settings'
import { CodeIcon, FacebookIcon, PinterestIcon, TwitterIcon } from '@cma/icons'
import { ArrowRightIcon, CheckIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate } from 'react-router-dom'
import wmhw from './images/wmhw.png'

export default function LeadGeneration() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const [showEmbedCode, setShowEmbedCode] = useState(false)
  const [copiedEmbedCode, setCopiedEmbedCode] = useState(false)
  const { mutate: updateUserConfig, error, isSuccess } = useUpdateUserConfig()
  const { data: { boostRedirectLink } = {} } = useBoost()

  useEffect(() => {
    if (!copiedEmbedCode) return

    const timer = setTimeout(() => {
      setCopiedEmbedCode(false)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [copiedEmbedCode])

  if (!currentUser?.features?.hasApiButton) {
    return <Navigate to="/settings" />
  }

  const widgetUrl = `${process.env.VITE_APP_CMA_APP_URL}/api_widget/${currentUser?.guid}/show`
  const fullWidgetUrl = `${widgetUrl}?post_url=${window.location.origin}&source_url=ua`
  const socialTitle = encodeURIComponent(
    "What's my home worth? Get a free Consumer Market Analysis"
  )
  const socialMessage = encodeURIComponent(
    'Hey, I would like to send you a personalized Comparative Market Analysis (CMA) on your home.'
  )
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${widgetUrl}?post_url=${process.env.VITE_APP_CMA_URL}&source_url=facebook.com")}&t=${socialTitle}`
  )}`
  const twitterUrl = `https://twitter.com/intent/tweet?text=${socialMessage}&url=${encodeURIComponent(
    `${widgetUrl}?post_url=${process.env.VITE_APP_CMA_URL}&source_url=twitter.com`
  )}&via=cloudcma`
  const pinterestUrl = `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
    `${widgetUrl}?post_url=${process.env.VITE_APP_CMA_URL}`
  )}&media=${encodeURIComponent(
    currentUser?.avatar || ''
  )}&description=${socialMessage} ${encodeURIComponent(
    `${widgetUrl}?post_url=${process.env.VITE_APP_CMA_URL}`
  )}`
  const embedCode = `<script id="cloudcma_api_widget_script" src="${process.env.VITE_APP_CMA_URL}/api_widget/${currentUser?.guid}/new?format=js"></script>`

  return (
    <Wrapper>
      <Helmet>
        <title>Lead Generation - Account Settings - Cloud CMA</title>
      </Helmet>
      <div className="mx-auto max-w-xl space-y-12">
        <h1 className="text-center text-4xl">Lead Generation</h1>
        <div className="space-y-4 text-center">
          <h2 className="text-center text-2xl text-gray-700">
            Your Landing Page
          </h2>
          <p className="text-sm text-gray-700">
            Generate leads with your{' '}
            <strong className="font-medium">"What's my home worth?"</strong>{' '}
            landing page
          </p>
          <a
            href={fullWidgetUrl}
            target="_blank"
            rel="noreferrer"
            className="inline-block">
            <img
              src={wmhw}
              alt="What's My Home Worth?"
              className="mx-auto max-w-sm rounded-lg shadow"
            />
          </a>
          <div>
            <Button as="a" href={fullWidgetUrl} target="_blank">
              View Landing Page
            </Button>
          </div>
          <div>
            <button
              className="helix-text-brand text-sm"
              onClick={() => copyTextToClipboard(fullWidgetUrl)}>
              Copy link
            </button>
          </div>
        </div>
        {boostRedirectLink?.url && (
          <div className="space-y-6 rounded-lg bg-white p-6 text-center shadow">
            <p className="text-sm text-gray-900">
              <strong>Want to find new clients faster?</strong> 🚀&nbsp; Boost
              advertises your landing page on Facebook, Instagram, and across
              the web.
            </p>
            <Button as="a" href={boostRedirectLink.url} target="_blank">
              Boost Landing Page
            </Button>
          </div>
        )}
        <div className="space-y-4">
          <h2 className="text-center text-2xl text-gray-700">Notifications</h2>
          <div className="space-y-2">
            <div className="flex justify-center">
              <Checkbox
                onChange={(e) =>
                  updateUserConfig({
                    input: {
                      autoSendWebleadEmail: e.target.checked
                    }
                  })
                }
                checked={currentUser?.autoSendWebleadEmail ?? false}>
                Automatically email a finished report to all of your web leads
              </Checkbox>
              {isSuccess && (
                <div className="sr-only" role="alert" aria-live="polite">
                  Updated web lead settings
                </div>
              )}
            </div>
            <i className="mx-auto block max-w-xs text-center text-xs leading-5 text-gray-400">
              If you uncheck this box, your web lead will not be sent an email,
              but you will still be notified via email.
            </i>
            {error && (
              <div
                role="alert"
                aria-live="polite"
                className="text-center text-sm text-red-600">
                {error.message}
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4">
          <h2 className="text-center text-2xl text-gray-700">Share</h2>
          <div className="grid grid-cols-4 gap-4">
            <button
              className="flex flex-col items-center justify-center space-y-3 rounded-md bg-white p-6 shadow transition-all hover:-translate-y-1 hover:shadow-lg"
              onClick={() => setShowEmbedCode(true)}>
              <CodeIcon className="h-8 w-8 text-red-500" />
              <span className="text-sm text-gray-500">Embed</span>
            </button>
            <a
              href={facebookUrl}
              target="_blank"
              rel="noreferrer"
              className="flex flex-col items-center justify-center space-y-3 rounded-md bg-white p-6 shadow transition-all hover:-translate-y-1 hover:shadow-lg">
              <FacebookIcon className="h-8 w-8" />
              <div className="text-sm text-gray-500">Facebook</div>
            </a>
            <a
              href={twitterUrl}
              target="_blank"
              rel="noreferrer"
              className="flex flex-col items-center justify-center space-y-3 rounded-md bg-white p-6 shadow transition-all hover:-translate-y-1 hover:shadow-lg">
              <TwitterIcon className="h-8 w-8" />
              <div className="text-sm text-gray-500">Twitter</div>
            </a>
            <a
              href={pinterestUrl}
              target="_blank"
              rel="noreferrer"
              className="flex flex-col items-center justify-center space-y-3 rounded-md bg-white p-6 shadow transition-all hover:-translate-y-1 hover:shadow-lg">
              <PinterestIcon className="h-8 w-8" />
              <div className="text-sm text-gray-500">Pinterest</div>
            </a>
          </div>
        </div>
        <div>
          <div className="mb-2 flex justify-center">
            <div className="inline-block rounded-sm bg-red-500 px-1 text-[0.5625rem] uppercase text-white">
              New
            </div>
          </div>
          <h2 className="mb-4 text-center text-2xl text-gray-700">
            Want more out of your landing page?
          </h2>
          <div className="mb-4 flex justify-center">
            <ol className="space-y-2 text-sm text-gray-500">
              <li className="flex items-center space-x-2">
                <CheckIcon className="h-5 w-5 text-red-500" />
                <span>Customize the design including background image</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckIcon className="h-5 w-5 text-red-500" />
                <span>Send a home value range instead of a full report</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckIcon className="h-5 w-5 text-red-500" />
                <span>Create unlimited pages to target neighborhoods</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckIcon className="h-5 w-5 text-red-500" />
                <span>Create pages that also engage buyers</span>
              </li>
            </ol>
          </div>
          <div className="text-center">
            <a
              href={`${process.env.VITE_APP_CAS_URL}/tools/attract`}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center space-x-2 text-sm text-red-600">
              <span>Try Cloud Attract free</span>
              <ArrowRightIcon className="h-4 w-4" />
            </a>
          </div>
        </div>
      </div>
      <Modal isOpen={showEmbedCode} onClose={() => setShowEmbedCode(false)}>
        <Modal.Title>Embed</Modal.Title>
        <div className="mt-4 space-y-4">
          <Input as="textarea" rows={5} defaultValue={embedCode} readOnly />
          <div className="flex justify-end">
            <Button
              onClick={() => {
                copyTextToClipboard(embedCode)
                setCopiedEmbedCode(true)
              }}>
              {copiedEmbedCode ? 'Code Copied!' : 'Copy Code'}
            </Button>
          </div>
        </div>
      </Modal>
    </Wrapper>
  )
}
