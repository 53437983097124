import { classNames, Modal } from '@cma/common'
import { EyeIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { CustomizePageWithPages } from './useCustomizePages'

interface CustomizePagePreviewProps {
  page: CustomizePageWithPages
}

export function CustomizePagePreview({ page }: CustomizePagePreviewProps) {
  const [showPreview, setShowPreview] = useState(false)
  const [showPreviewEye, setShowPreviewEye] = useState(false)

  return (
    <>
      <button
        type="button"
        className={classNames(
          'inline-flex min-w-0 items-center space-x-2 text-left',
          {
            'hover:helix-text-brand truncate text-gray-700': !page.active,
            'font-medium': page.active
          }
        )}
        title={page.title}
        aria-label={`Preview ${page.title}`}
        data-testid="customize-active-page-title"
        onClick={() => setShowPreview(true)}
        onFocus={() => setShowPreviewEye(!page.isParent)}
        onBlur={() => setShowPreviewEye(false)}
        onMouseEnter={() => setShowPreviewEye(!page.isParent)}
        onMouseLeave={() => setShowPreviewEye(false)}>
        <span className="truncate">{page.title}</span>
        {showPreviewEye && <EyeIcon className="h-4 w-4 text-gray-300" />}
      </button>
      <Modal isOpen={showPreview} onClose={() => setShowPreview(false)}>
        <Modal.Title>{page.title} - Preview</Modal.Title>
        <div className="mt-2">PDF Preview Here.</div>
      </Modal>
    </>
  )
}
