import { NotFound, Salesforce, Spinner } from '@cma/common'
import { BrokerHeader } from '@cma/features/admin/broker'
import { Intercom } from '@cma/features/intercom'
import { Pendo } from '@cma/features/pendo'
import Broker from '@cma/pages/admin/broker/Broker'
import BrokerDetails from '@cma/pages/admin/broker/BrokerDetails'
import Brokers from '@cma/pages/admin/broker/Brokers'
import InvitationExpired from '@cma/pages/admin/broker/InvitationExpired'
import Onboarding from '@cma/pages/admin/broker/Onboarding'
import AuthLayout from '@cma/pages/auth/AuthLayout'
import Login from '@cma/pages/auth/Login'
import PasswordRequestReset from '@cma/pages/auth/PasswordRequestReset'
import PasswordReset from '@cma/pages/auth/PasswordReset'
import CmaCustomize from '@cma/pages/cma/CmaCustomize'
import CmaDetails from '@cma/pages/cma/CmaDetails'
import CmaListings from '@cma/pages/cma/CmaListings'
import CmaPublish from '@cma/pages/cma/CmaPublish'
import Cmas from '@cma/pages/cma/Cmas'
import DocumentCustomize from '@cma/pages/document/DocumentCustomize'
import DocumentDetails from '@cma/pages/document/DocumentDetails'
import DocumentListings from '@cma/pages/document/DocumentListings'
import DocumentPublish from '@cma/pages/document/DocumentPublish'
import Documents from '@cma/pages/document/Documents'
import FlyerCustomize from '@cma/pages/flyer/FlyerCustomize'
import FlyerDetails from '@cma/pages/flyer/FlyerDetails'
import FlyerListings from '@cma/pages/flyer/FlyerListings'
import FlyerPublish from '@cma/pages/flyer/FlyerPublish'
import Flyers from '@cma/pages/flyer/Flyers'
import HomeWorth from '@cma/pages/home-worth/HomeWorth'
import Home from '@cma/pages/home/Home'
import Homebeat from '@cma/pages/homebeat/Homebeat'
import Homebeats from '@cma/pages/homebeat/Homebeats'
import Live from '@cma/pages/live/Live'
import Present from '@cma/pages/present/Present'
import Properties from '@cma/pages/property/Properties'
import PropertyCustomize from '@cma/pages/property/PropertyCustomize'
import PropertyDetails from '@cma/pages/property/PropertyDetails'
import PropertyListings from '@cma/pages/property/PropertyListings'
import PropertyPublish from '@cma/pages/property/PropertyPublish'
import BillingOverview from '@cma/pages/settings/BillingOverview'
import BillingPayments from '@cma/pages/settings/BillingPayments'
import BillingReceipt from '@cma/pages/settings/BillingReceipt'
import Connection from '@cma/pages/settings/Connection'
import ContactInfo from '@cma/pages/settings/ContactInfo'
import CustomPages from '@cma/pages/settings/CustomPages'
import Integrations from '@cma/pages/settings/Integrations'
import LeadGeneration from '@cma/pages/settings/LeadGeneration'
import MlsCredentials from '@cma/pages/settings/MlsCredentials'
import Password from '@cma/pages/settings/Password'
import Profile from '@cma/pages/settings/Profile'
import SettingsLayout from '@cma/pages/settings/SettingsLayout'
import SsoConnect from '@cma/pages/sso/SsoConnect'
import SsoLayout from '@cma/pages/sso/SsoLayout'
import SsoMaginLogin from '@cma/pages/sso/SsoMagicLogin'
import SsoOnboarding from '@cma/pages/sso/SsoOnboarding'
import TourDetails from '@cma/pages/tour/TourDetails'
import TourLive from '@cma/pages/tour/TourLive'
import Tours from '@cma/pages/tour/Tours'
import { Suspense, lazy } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import { AppHeader } from './AppHeader'
import { UserRole, useCurrentUser } from './useCurrentUser'

// Admin
const AccountSettings = lazy(
  () => import('@cma/pages/admin/accounts/AccountSettings')
)
const AccountSetting = lazy(
  () => import('@cma/pages/admin/accounts/AccountSetting')
)

export function AppRoutes() {
  const navigate = useNavigate()
  return (
    <Routes>
      <Route path="/" element={<AuthedApp />}>
        <Route path="/" element={<Home />} />

        <Route path="/cmas" element={<Cmas />} />
        <Route path="/cmas/new" element={<CmaDetails />} />
        <Route path="/cmas/:id/edit" element={<CmaDetails />} />
        <Route path="/cmas/:id" element={<CmaListings />} />
        <Route path="/cmas/:id/customize" element={<CmaCustomize />} />
        <Route path="/cmas/:id/publish" element={<CmaPublish />} />
        <Route path="/tours" element={<Tours />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/properties/new" element={<PropertyDetails />} />
        <Route path="/properties/:id/edit" element={<PropertyDetails />} />
        <Route path="/properties/:id" element={<PropertyListings />} />
        <Route
          path="/properties/:id/customize"
          element={<PropertyCustomize />}
        />
        <Route path="/properties/:id/publish" element={<PropertyPublish />} />
        <Route path="/flyers" element={<Flyers />} />
        <Route path="/flyers/new" element={<FlyerDetails />} />
        <Route path="/flyers/:id/edit" element={<FlyerDetails />} />
        <Route path="/flyers/:id" element={<FlyerListings />} />
        <Route path="/flyers/:id/customize" element={<FlyerCustomize />} />
        <Route path="/flyers/:id/publish" element={<FlyerPublish />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/documents/new" element={<DocumentDetails />} />
        <Route path="/documents/:id/edit" element={<DocumentDetails />} />
        <Route path="/documents/:id" element={<DocumentListings />} />
        <Route
          path="/documents/:id/customize"
          element={<DocumentCustomize />}
        />
        <Route path="/documents/:id/publish" element={<DocumentPublish />} />
        <Route path="/homebeats" element={<Homebeats />} />
        <Route path="/settings/lead-generation" element={<LeadGeneration />} />
        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="/settings" element={<Profile />} />
          <Route path="/settings/contact-info" element={<ContactInfo />} />
          <Route path="/settings/password" element={<Password />} />
          <Route
            path="/settings/mls-credentials"
            element={<MlsCredentials />}
          />
          <Route path="/settings/custom-pages" element={<CustomPages />} />
          <Route path="/settings/integrations" element={<Integrations />} />
          <Route path="/settings/billing" element={<Outlet />}>
            <Route path="/settings/billing" element={<BillingOverview />} />
            <Route
              path="/settings/billing/payments"
              element={<BillingPayments />}
            />
          </Route>
          <Route path="*" element={<RouteNotFound />} />
        </Route>
      </Route>

      <Route path="/" element={<AuthedApp hideHeader />}>
        <Route path="/users/:userId/receipt" element={<BillingReceipt />} />

        <Route path="/tours/new" element={<TourDetails />} />
        <Route path="/tours/:id/edit" element={<TourDetails />} />
        <Route path="/product_connector" element={<Connection />} />
        <Route path="/settings/connection" element={<Connection />} />
      </Route>

      <Route path="/tours/:id" element={<TourLive />} />

      <Route
        path="/onboarding"
        element={<Onboarding isOpen={true} onClose={() => navigate('/')} />}
      />
      <Route path="/expired_invitation" element={<InvitationExpired />} />

      <Route path="/admin" element={<AdminApp />}>
        <Route path="brokers" element={<Brokers />} />
        <Route path="accounts/settings" element={<AccountSettings />} />
        <Route path="accounts/settings/new" element={<AccountSetting />} />
        <Route path="accounts/settings/:id" element={<AccountSetting />} />
      </Route>

      <Route path="/admin/brokers" element={<AdminBrokerApp />}>
        <Route path=":brokerId" element={<Broker />} />
        <Route path="new" element={<BrokerDetails />} />
        <Route path=":brokerId/edit" element={<BrokerDetails />} />
      </Route>

      <Route path="/" element={<BrokerAdminApp />}>
        <Route path="/dashboard" element={<Broker />} />
      </Route>

      {/* Public routes */}
      <Route path="/" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="password/new" element={<PasswordRequestReset />} />
        <Route path="password/edit" element={<PasswordReset />} />
      </Route>

      <Route path="/present/:guid" element={<Present />} />
      <Route path="/live/:guid" element={<Live />} />
      <Route path="/homebeats/:guid" element={<Homebeat />} />
      <Route path="/api_widget/:guid/show" element={<HomeWorth />} />

      <Route path="/sso" element={<SsoLayout />}>
        <Route path=":service" element={<SsoOnboarding />} />
        <Route path="email_login/:service" element={<SsoMaginLogin />} />
        <Route path="connect/:service" element={<SsoConnect />} />
        <Route path="*" element={<RouteNotFound />} />
      </Route>

      <Route path="*" element={<RouteNotFound />} />
    </Routes>
  )
}

interface AuthedAppProps {
  hideHeader?: boolean
}

function AuthedApp({ hideHeader }: AuthedAppProps) {
  const { data: { currentUser } = {} } = useCurrentUser()
  const location = useLocation()

  if (!currentUser) {
    const params = new URLSearchParams(location.search)
    params.delete('jwt')
    params.delete('impersonate')
    return (
      <Navigate
        to="/login"
        state={{ from: { ...location, search: `?${params.toString()}` } }}
      />
    )
  }

  if (
    currentUser.status === 'onboarding' &&
    location.pathname != '/onboarding'
  ) {
    return <Navigate to="/onboarding" />
  }

  return (
    <>
      {!hideHeader && <AppHeader />}
      {!hideHeader && process.env.NODE_ENV !== 'test' && (
        <>
          <Intercom />
          <Salesforce />
        </>
      )}
      <Suspense
        fallback={
          <div className="p-5">
            <div className="helix-text-brand h-5 w-5">
              <Spinner />
            </div>
          </div>
        }>
        <Pendo />
        <Outlet />
      </Suspense>
    </>
  )
}

function BrokerAdminApp() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const location = useLocation()

  if (!currentUser) {
    const params = new URLSearchParams(location.search)
    params.delete('jwt')
    return (
      <Navigate
        to="/login"
        state={{ from: { ...location, search: `?${params.toString()}` } }}
      />
    )
  }

  if (
    currentUser.status === 'onboarding' &&
    location.pathname != '/onboarding'
  ) {
    return <Navigate to="/onboarding" />
  }

  if ((currentUser.role || 0) >= UserRole.ADMIN_PLUS) {
    return <Navigate to="/admin/brokers" />
  }

  if ((currentUser.role || 0) < UserRole.BROKER_ADMIN) {
    return <RouteNotFound />
  }

  return (
    <>
      <AppHeader />
      <Suspense
        fallback={
          <div className="p-5">
            <div className="helix-text-brand h-5 w-5">
              <Spinner />
            </div>
          </div>
        }>
        <Outlet />
      </Suspense>
    </>
  )
}

function AdminApp() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const location = useLocation()

  if (!currentUser) {
    const params = new URLSearchParams(location.search)
    params.delete('jwt')
    return (
      <Navigate
        to="/login"
        state={{ from: { ...location, search: `?${params.toString()}` } }}
      />
    )
  }

  if (
    currentUser.status === 'onboarding' &&
    location.pathname != '/onboarding'
  ) {
    return <Navigate to="/onboarding" />
  }

  if ((currentUser.role || 0) < UserRole.ADMIN_PLUS) {
    return <RouteNotFound />
  }

  return (
    <>
      <AppHeader />
      <Suspense
        fallback={
          <div className="p-5">
            <div className="helix-text-brand h-5 w-5">
              <Spinner />
            </div>
          </div>
        }>
        <Outlet />
      </Suspense>
    </>
  )
}

function AdminBrokerApp() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const location = useLocation()

  if (!currentUser) {
    const params = new URLSearchParams(location.search)
    params.delete('jwt')
    return (
      <Navigate
        to="/login"
        state={{ from: { ...location, search: `?${params.toString()}` } }}
      />
    )
  }

  if (
    currentUser.status === 'onboarding' &&
    location.pathname != '/onboarding'
  ) {
    return <Navigate to="/onboarding" />
  }

  if ((currentUser.role || 0) < UserRole.BROKER_ADMIN) {
    return <RouteNotFound />
  }

  const isAdminPlus = (currentUser.role || 0) >= UserRole.ADMIN_PLUS

  return (
    <>
      {isAdminPlus ? <BrokerHeader /> : <AppHeader />}
      <Suspense
        fallback={
          <div className="p-5">
            <div className="helix-text-brand h-5 w-5">
              <Spinner />
            </div>
          </div>
        }>
        <Outlet />
      </Suspense>
    </>
  )
}

function RouteNotFound() {
  return (
    <div className="m-auto mt-16 w-4/5 py-8">
      <NotFound />
    </div>
  )
}
