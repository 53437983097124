import { ChevronDownIcon } from '@heroicons/react/solid'
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'
import { classNames } from './utils'

interface SelectProps
  extends Omit<JSX.IntrinsicElements['select'], 'className'> {
  variant?: 'primary' | 'danger'
  fullWidth?: boolean
}

export const Select = forwardRef(function Select(
  {
    variant = 'primary',
    fullWidth,
    disabled,
    value,
    onChange,
    ...props
  }: SelectProps,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const internalRef = useRef<HTMLSelectElement>()
  const [internalValue, setInternalValue] = useState(value)

  // To set Internal state if value is set
  useEffect(() => {
    setInternalValue(value || internalRef.current?.value)
  }, [value])

  return (
    <div className="relative rounded-md bg-white">
      <ChevronDownIcon className="absolute right-1 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-500" />
      <select
        ref={(instance) => {
          if (instance) {
            internalRef.current = instance
            if (typeof ref === 'function') {
              ref(instance)
            }
          }
        }}
        value={internalValue}
        {...props}
        disabled={disabled}
        onChange={(e) => {
          setInternalValue(e.target.value)
          onChange?.(e)
        }}
        className={classNames(
          'relative w-full appearance-none rounded-md border bg-transparent p-2 pr-6 shadow-sm focus:outline-none sm:text-sm',
          {
            'border-gray-300': variant === 'primary',
            'focus:border-brand-primary': variant === 'primary' && !disabled,

            'border-red-300': variant === 'danger',
            'focus:border-red-500': variant === 'danger' && !disabled,

            'cursor-pointer': !disabled,
            'cursor-not-allowed bg-gray-50': disabled,

            'text-gray-400': !internalValue,

            'w-full': fullWidth
          }
        )}
      />
    </div>
  )
})
