import {
  AddressSearch,
  Button,
  ErrorBoundary,
  Flash,
  FormField,
  Input,
  NotFound,
  Spinner,
  useQueryParams,
  Wrapper
} from '@cma/common'
import { useCreateFlyer, useUpdateFlyer } from '@cma/features/flyer'
import { ReportHeader, useReport } from '@cma/features/report'
import { LockClosedIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  client: yup.string().required("The client can't be blank"),
  privateNotes: yup.string(),
  mlsNumOrAddress: yup
    .string()
    .required("The address or MLS number can't be blank"),
  lat: yup.number(),
  lon: yup.number(),
  mlsnum: yup.string(),
  address: yup.string()
})

function FlyerDetails() {
  const { id } = useParams()
  const { data: { report } = {} } = useReport({ id: id as string })
  const createFlyer = useCreateFlyer()
  const updateFlyer = useUpdateFlyer()
  const navigate = useNavigate()
  const isEditing = !!useMatch('/flyers/:id/edit')
  const queryParams = useQueryParams()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      client: queryParams.get('title') || report?.title || undefined,
      privateNotes: queryParams.get('notes') || report?.notes || undefined,
      mlsNumOrAddress:
        queryParams.get('address') ||
        queryParams.get('mlsnums') ||
        report?.address ||
        undefined,
      address: queryParams.get('address') || report?.address || undefined,
      mlsnum: queryParams.get('mlsnums') || undefined,
      lat:
        Number(queryParams.get('lat') || report?.subjectProperty?.lat) ||
        undefined,
      lon:
        Number(queryParams.get('lon') || report?.subjectProperty?.lon) ||
        undefined
    }
  })
  const loading = (isEditing && updateFlyer.isLoading) || createFlyer.isLoading
  const createFlyerError = createFlyer?.error?.message
  const updateFlyerError = updateFlyer?.error?.message
  const serverErrorMessage = createFlyerError ?? updateFlyerError ?? ''
  const [isUpdatingDetailsOnly, setIsUpdatingDetailsOnly] = useState(false)

  if (isEditing && (!report || !id)) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const payload = {
          title: data.client,
          notes: data.privateNotes,
          mlsnum: data.mlsnum,
          lat: data.lat,
          lon: data.lon,
          address: data.address || data.mlsNumOrAddress
        }

        if (isEditing && id) {
          updateFlyer.mutate(
            { id, input: payload, updateListings: true },
            {
              onSuccess: () => {
                navigate(`/flyers/${id}`)
              }
            }
          )
        } else {
          createFlyer.mutate(
            { input: payload },
            {
              onSuccess: (data) => {
                navigate(`/flyers/${data.createFlyer?.flyer?.id}`)
              }
            }
          )
        }
      })}>
      <Wrapper>
        {serverErrorMessage && (
          <Flash variant="error">{serverErrorMessage}</Flash>
        )}
        <Helmet>
          <title>
            {isEditing ? 'Edit Details' : 'Create Details'} - Flyer - Cloud CMA
          </title>
        </Helmet>
        <ReportHeader
          reportId={id || ''}
          title={isEditing ? 'Edit Flyer' : 'Create Flyer'}
          breadcrumbs={[
            {
              title: 'Criteria',
              url: isEditing ? `/flyers/${id}/edit` : '/flyers/new'
            },
            { title: 'Listings', url: `/flyers/${id}`, disabled: !isEditing },
            {
              title: 'Customize',
              url: `/flyers/${id}/customize`,
              disabled: !isEditing
            },
            {
              title: 'Publish',
              url: `/flyers/${id}/publish`,
              disabled: !isEditing || loading,
              publish: true
            }
          ]}>
          <Button
            disabled={isUpdatingDetailsOnly || loading}
            loading={!isUpdatingDetailsOnly && loading}>
            {isEditing ? 'Update Listings' : 'Fetch Listings'}
          </Button>
        </ReportHeader>
        <main className="space-y-6">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 space-y-4 rounded-lg bg-white p-5 shadow md:col-span-6 md:col-start-4">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-medium">Name the Report</h2>
                {isEditing && (
                  <button
                    className="helix-text-brand text-sm disabled:cursor-not-allowed disabled:opacity-50"
                    type="button"
                    disabled={updateFlyer.isLoading}
                    onClick={() => {
                      if (id) {
                        setIsUpdatingDetailsOnly(true)
                        const data = getValues()
                        updateFlyer.mutate(
                          {
                            id,
                            input: {
                              title: data.client,
                              notes: data.privateNotes,
                              mlsnum: data.mlsnum,
                              lat: data.lat,
                              lon: data.lon,
                              address: data.address || data.mlsNumOrAddress
                            },
                            updateListings: false
                          },
                          {
                            onSuccess: () => {
                              navigate(`/flyers/${id}`)
                            }
                          }
                        )
                      }
                    }}>
                    {isUpdatingDetailsOnly && updateFlyer.isLoading ? (
                      <div className="flex items-center space-x-2">
                        <div className="h-5 w-5">
                          <Spinner />
                        </div>
                        <div>Updating</div>
                      </div>
                    ) : (
                      'Update'
                    )}
                  </button>
                )}
              </div>
              <div className="space-y-4">
                <FormField
                  label="Client"
                  required
                  error={errors.client?.message}>
                  <Input autoFocus {...register('client')} />
                </FormField>
                <FormField
                  label={
                    <span className="flex items-center space-x-1">
                      <span>Private Notes</span>
                      <LockClosedIcon className="h-3 w-3" />
                    </span>
                  }
                  tip="Private notes don't appear in the report"
                  error={errors.privateNotes?.message}>
                  <Input
                    as="textarea"
                    rows={6}
                    maxLength={255}
                    {...register('privateNotes')}
                  />
                </FormField>
                <FormField
                  required
                  label="Address or MLS Number"
                  error={errors.mlsNumOrAddress?.message}>
                  <AddressSearch
                    {...register('mlsNumOrAddress')}
                    defaultValue={getValues('mlsNumOrAddress')}
                    onInputChange={(value) => {
                      setValue('address', value)
                      setValue('mlsnum', value)
                      setValue('lat', undefined)
                      setValue('lon', undefined)
                    }}
                    onSelect={(address) => {
                      setValue('address', address.formattedAddress)
                      setValue('mlsnum', address.mlsnum || '')
                      setValue('lat', address.lat || undefined)
                      setValue('lon', address.lon || undefined)
                    }}
                  />
                </FormField>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              size="lg"
              disabled={isUpdatingDetailsOnly || loading}
              loading={!isUpdatingDetailsOnly && loading}>
              {isEditing ? 'Update Listings' : 'Fetch Listings'}
            </Button>
          </div>
        </main>
      </Wrapper>
    </form>
  )
}

export default function FlyerDetailsProvider() {
  return (
    <ErrorBoundary
      fallback={
        <div className="flex flex-grow items-center justify-center">
          <NotFound />
        </div>
      }>
      <FlyerDetails />
    </ErrorBoundary>
  )
}
