import { classNames, Dropdown, formatNumber, Tooltip } from '@cma/common'
import { MlsPartsFragment, SingleMlsMonthlyStats } from '@cma/generated/graphql'
import { InfoIcon } from '@cma/icons'
import { Suspense, useRef, useState } from 'react'
import { useBrokerStats } from './useBrokerStats'

export enum MonthAbbreviations {
  January = 'Jan',
  February = 'Feb',
  March = 'Mar',
  April = 'Apr',
  May = 'May',
  June = 'Jun',
  July = 'July',
  August = 'Aug',
  September = 'Sept',
  October = 'Oct',
  November = 'Nov',
  December = 'Dec'
}
type MonthStats = Omit<SingleMlsMonthlyStats, 'mls' | 'month'>
type Month = keyof typeof MonthAbbreviations
const months: Month[] = Object.keys(MonthAbbreviations) as Month[]

export function getPastMonths() {
  const currentMonth = new Date().getMonth()
  let index = currentMonth
  const pastMonths: Month[] = []
  while (pastMonths.length !== 12) {
    pastMonths.push(months[(index + months.length) % months.length])
    index -= 1
  }
  return pastMonths.reverse()
}

export function getCurrentMonth() {
  return months[new Date().getMonth()]
}

export function getStatsByMonth(
  stats: SingleMlsMonthlyStats[] | undefined,
  selectedMlses: string[]
) {
  return (stats || [])
    .filter((data) =>
      data.mls.key ? selectedMlses.includes(data.mls.key) : false
    )
    .reduce<Record<string, MonthStats | undefined>>((byMonth, stats) => {
      const month: MonthStats = byMonth[stats.month] || {
        totalUsers: 0,
        totalActiveUsers: 0,
        cmaActiveUsers: 0,
        flyerActiveUsers: 0,
        buyerActiveUsers: 0,
        propertyActiveUsers: 0,
        homebeatActiveUsers: 0
      }
      return {
        ...byMonth,
        [stats.month]: {
          totalUsers: month.totalUsers
            ? month.totalUsers + stats.totalUsers
            : stats.totalUsers,
          totalActiveUsers: month.totalActiveUsers
            ? month.totalActiveUsers + stats.totalActiveUsers
            : stats.totalActiveUsers,
          cmaActiveUsers: month.cmaActiveUsers
            ? month.cmaActiveUsers + stats.cmaActiveUsers
            : stats.cmaActiveUsers,
          flyerActiveUsers: month.flyerActiveUsers
            ? month.flyerActiveUsers + stats.flyerActiveUsers
            : stats.flyerActiveUsers,
          buyerActiveUsers: month.buyerActiveUsers
            ? month.buyerActiveUsers + stats.buyerActiveUsers
            : stats.buyerActiveUsers,
          propertyActiveUsers: month.propertyActiveUsers
            ? month.propertyActiveUsers + stats.propertyActiveUsers
            : stats.propertyActiveUsers,
          homebeatActiveUsers: month.homebeatActiveUsers
            ? month.homebeatActiveUsers + stats.homebeatActiveUsers
            : stats.homebeatActiveUsers
        }
      }
    }, {})
}

export function getAllMlses(stats?: SingleMlsMonthlyStats[]) {
  return Object.values(
    (stats || []).reduce<Record<string, MlsPartsFragment>>((byMls, data) => {
      if (data.mls.key) {
        return { ...byMls, [data.mls.key]: data.mls }
      }
      return byMls
    }, {})
  )
}

interface BrokerStatsProps {
  brokerId: string
}

function BrokerStats_({ brokerId }: BrokerStatsProps) {
  const pastMonths = getPastMonths()
  const { data: brokerStatsQuery, isSuccess } = useBrokerStats({
    id: brokerId as string
  })
  const stats = brokerStatsQuery?.broker?.stats.monthlyActiveUsers?.reduce<
    SingleMlsMonthlyStats[]
  >((allStats, stats) => [...allStats, ...(stats.singleMonthStats || [])], [])
  const [activeMonth, setActiveMonth] = useState<Month>(() => getCurrentMonth())
  const mlses = getAllMlses(stats || [])
  const [selectedMlses, setSelectedMlses] = useState<string[]>(
    mlses.map((mls) => mls.key).filter((key): key is string => !!key)
  )
  const statsByMonth = getStatsByMonth(stats || [], selectedMlses)
  const activeMonthStats = statsByMonth[activeMonth]
  const totalUsers = Math.max(
    0,
    ...Object.values(statsByMonth).map((month) => month?.totalUsers || 0)
  )
  const totalYAxis = Math.max(0, Math.min(totalUsers, 4))
  const [tooltipVisibilityMonth, setTooltipVisibilityMonth] = useState('')

  return (
    <div className="flex divide-x rounded-lg bg-white shadow">
      <div className="w-8/12 p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <h2 className="flex items-center space-x-1 font-semibold text-gray-900">
              <span>Active users</span>
              <Tooltip
                content={`Active users that have created at least one report in ${activeMonth}.`}>
                <button>
                  <InfoIcon className="h-4 w-4 text-gray-400" />
                </button>
              </Tooltip>
            </h2>
            <div className="text-gray-500">
              <span data-testid="active-month">{activeMonth}</span>{' '}
              <span
                data-testid="active-month-total-active-users"
                className="helix-text-brand">
                {statsByMonth[activeMonth]?.totalActiveUsers || 0}
              </span>
              /
              <span data-testid="active-month-total-users">
                {statsByMonth[activeMonth]?.totalUsers || 0}
              </span>
            </div>
          </div>
          <div className="w-40">
            <Dropdown
              multiple
              disabled={isSuccess && !stats?.length}
              value={selectedMlses}
              onChange={setSelectedMlses}>
              <Dropdown.Button>
                {isSuccess && !stats?.length
                  ? 'No MLSs Available'
                  : !selectedMlses.length
                  ? 'No MLSs Selected'
                  : selectedMlses.length === mlses.length
                  ? 'All MLSs'
                  : mlses
                      .filter((mls) =>
                        selectedMlses.includes(mls.key || 'NO_KEY_PROVIDED')
                      )
                      .map((mls) => mls.title)
                      .join(', ')}
              </Dropdown.Button>
              {mlses.map((mls) => (
                <Dropdown.Option
                  key={mls.key}
                  value={mls.key || 'NO_KEY_PROVIDED'}>
                  {mls.title}
                </Dropdown.Option>
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="mt-4 flex h-[8.5rem]">
          {/* Y Axis */}
          {!!selectedMlses.length && (
            <div
              data-testid="active-users-y-axis"
              className="mr-4 flex flex-shrink-0 flex-col justify-between text-right font-mono text-[0.625rem] leading-none text-gray-600">
              {/* Adding 1 to account for "0" */}
              {new Array(totalYAxis + 1)
                .fill('')
                .map((_, index) => Math.ceil(totalUsers * (index * 0.25)))
                .reverse()
                .map((total) => (
                  <div key={total} data-testid="active-users-y-axis-value">
                    {total}
                  </div>
                ))}
            </div>
          )}

          {/* Bars */}
          <div className="-mt-0.5 flex w-full justify-between space-x-2 border-b border-gray-100 lg:space-x-4 xl:space-x-8">
            {pastMonths.map((month) => {
              const monthStats = statsByMonth[month]
              return (
                <button
                  key={month}
                  aria-label={`Stats for ${month}`}
                  className="relative z-0 w-7 text-left"
                  onMouseOver={() => {
                    setActiveMonth(month)
                    setTooltipVisibilityMonth(month)
                  }}
                  // onMouseOver accompanied by onFocus for accessibility
                  onFocus={() => {
                    setActiveMonth(month)
                    setTooltipVisibilityMonth(month)
                  }}
                  onMouseLeave={() => {
                    setTooltipVisibilityMonth('')
                  }}
                  onBlur={() => {
                    setTooltipVisibilityMonth('')
                  }}>
                  <div
                    style={{
                      height: monthStats
                        ? `${(monthStats.totalUsers / totalUsers || 0) * 100}%`
                        : 0
                    }}
                    className="absolute bottom-0 w-full rounded-tl rounded-tr bg-gray-200 transition-all"
                  />
                  <Tooltip
                    visible={tooltipVisibilityMonth === month}
                    content={`${activeMonthStats?.totalActiveUsers} active users out of ${activeMonthStats?.totalUsers}`}>
                    <div
                      style={{
                        height: monthStats
                          ? `${
                              (monthStats.totalActiveUsers / totalUsers || 0) *
                              100
                            }%`
                          : 0
                      }}
                      className={classNames(
                        'absolute bottom-0 z-10 w-full rounded-tl rounded-tr transition-all',
                        {
                          'bg-gray-400': activeMonth !== month,
                          'bg-brand-primary': activeMonth === month
                        }
                      )}
                    />
                  </Tooltip>
                </button>
              )
            })}
          </div>
        </div>

        {/* X Axis */}
        <div className="mt-3 flex">
          {/* This helps align the month labels properly */}
          <div
            aria-hidden
            className="mr-4 flex flex-col justify-between text-right font-mono text-[0.625rem] leading-none text-transparent">
            {totalUsers}
          </div>
          <div className="flex w-full justify-between space-x-2 lg:space-x-4 xl:space-x-8">
            {pastMonths.map((month) => (
              <div
                key={month}
                data-testid="active-users-x-axis-month"
                className="w-7 text-center font-mono text-[0.625rem] leading-3 text-gray-600 hover:cursor-pointer"
                onMouseOver={() => {
                  setActiveMonth(month)
                  setTooltipVisibilityMonth(month)
                }}
                // onMouseOver accompanied by onFocus for accessibility
                onFocus={() => {
                  setActiveMonth(month)
                  setTooltipVisibilityMonth(month)
                }}
                onMouseLeave={() => {
                  setTooltipVisibilityMonth('')
                }}
                onBlur={() => {
                  setTooltipVisibilityMonth('')
                }}>
                {MonthAbbreviations[month]}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-4/12 space-y-3 p-6">
        <h2 className="flex items-center space-x-1 font-semibold text-gray-900">
          <span>Feature usage</span>
          <Tooltip content={`Active users by features in ${activeMonth}.`}>
            <button>
              <InfoIcon className="h-4 w-4 text-gray-400" />
            </button>
          </Tooltip>
        </h2>
        <div className="space-y-1.5">
          <div className="flex items-center justify-between space-x-2">
            <h3 className="text-xs text-gray-500">Feature</h3>
            <h3 className="text-xs text-gray-500">Active Users</h3>
          </div>
          <div className="space-y-2.5">
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">CMA</div>
                <div
                  className="h-1 rounded-full bg-gray-400 transition-all"
                  style={{
                    width: activeMonthStats
                      ? `${Math.min(
                          (activeMonthStats.cmaActiveUsers /
                            activeMonthStats.totalActiveUsers || 0) * 100,
                          100
                        )}%`
                      : 0
                  }}
                />
              </div>
              <div
                data-testid="feature-usage-cma-count"
                className="helix-text-brand flex-shrink-0 text-xs leading-none">
                {formatNumber(activeMonthStats?.cmaActiveUsers || 0)}
              </div>
            </div>
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">Buyer Tour</div>
                <div
                  className="h-1 rounded-full bg-gray-400 transition-all"
                  style={{
                    width: activeMonthStats
                      ? `${Math.min(
                          (activeMonthStats.buyerActiveUsers /
                            activeMonthStats.totalActiveUsers || 0) * 100,
                          100
                        )}%`
                      : 0
                  }}
                />
              </div>
              <div
                data-testid="feature-usage-buyer-count"
                className="helix-text-brand flex-shrink-0 text-xs leading-none">
                {formatNumber(activeMonthStats?.buyerActiveUsers || 0)}
              </div>
            </div>
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">Property Report</div>
                <div
                  className="h-1 rounded-full bg-gray-400 transition-all"
                  style={{
                    width: activeMonthStats
                      ? `${Math.min(
                          (activeMonthStats.propertyActiveUsers /
                            activeMonthStats.totalActiveUsers || 0) * 100,
                          100
                        )}%`
                      : 0
                  }}
                />
              </div>
              <div
                data-testid="feature-usage-property-count"
                className="helix-text-brand flex-shrink-0 text-xs leading-none">
                {formatNumber(activeMonthStats?.propertyActiveUsers || 0)}
              </div>
            </div>
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">Flyer</div>
                <div
                  className="h-1 rounded-full bg-gray-400 transition-all"
                  style={{
                    width: activeMonthStats
                      ? `${Math.min(
                          (activeMonthStats.flyerActiveUsers /
                            activeMonthStats.totalActiveUsers || 0) * 100,
                          100
                        )}%`
                      : 0
                  }}
                />
              </div>
              <div
                data-testid="feature-usage-flyer-count"
                className="helix-text-brand flex-shrink-0 text-xs leading-none">
                {formatNumber(activeMonthStats?.flyerActiveUsers || 0)}
              </div>
            </div>
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">Homebeat</div>
                <div
                  className="h-1 rounded-full bg-gray-400 transition-all"
                  style={{
                    width: activeMonthStats
                      ? `${Math.min(
                          (activeMonthStats.homebeatActiveUsers /
                            activeMonthStats.totalActiveUsers || 0) * 100,
                          100
                        )}%`
                      : 0
                  }}
                />
              </div>
              <div
                data-testid="feature-usage-homebeat-count"
                className="helix-text-brand flex-shrink-0 text-xs leading-none">
                {formatNumber(activeMonthStats?.homebeatActiveUsers || 0)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function BrokerStats({ brokerId }: BrokerStatsProps) {
  return (
    <Suspense fallback={<BrokerStatsSkeleton />}>
      <BrokerStats_ brokerId={brokerId} />
    </Suspense>
  )
}

function BrokerStatsSkeleton() {
  const pastMonths = getPastMonths()
  const activeMonth = getCurrentMonth()
  const fakeMonthStats = useRef<number[]>(
    new Array(12)
      .fill('')
      .map(() => Math.min(Math.max(Math.floor(Math.random() * 100), 40), 90))
  )
  const fakeReportStats = useRef<number[]>(
    new Array(4)
      .fill('')
      .map(() => Math.min(Math.max(Math.floor(Math.random() * 100), 40), 90))
  )

  return (
    <div className="flex h-[16.375rem] w-full divide-x rounded-lg bg-white shadow">
      <div className="w-8/12 p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <h2 className="flex items-center space-x-1 font-semibold text-gray-900">
              <span>Active users</span>
              <Tooltip
                content={`Active users that have created at least one report in ${activeMonth}.`}>
                <button>
                  <InfoIcon className="h-4 w-4 text-gray-400" />
                </button>
              </Tooltip>
            </h2>
            <div className="text-gray-500">
              <span data-testid="active-month">{activeMonth}</span>
            </div>
          </div>
          <div className="h-[2.375rem] w-40" />
        </div>
        <div className="mt-4 flex h-[8.5rem]">
          {/* Y Axis */}
          <div
            aria-hidden
            className="mr-4 flex flex-shrink-0 flex-col justify-between text-right font-mono text-[0.625rem] leading-none text-gray-600 text-transparent">
            0
          </div>

          {/* Bars */}
          <div className="-mt-0.5 flex w-full justify-between space-x-2 border-b border-gray-100 lg:space-x-4 xl:space-x-8">
            {fakeMonthStats.current.map((stat, index) => (
              <div key={index} className="relative z-0 w-7 text-left">
                <div
                  style={{
                    height: `${stat}%`
                  }}
                  className="absolute bottom-0 w-full animate-pulse rounded-tl rounded-tr bg-gray-200 transition-all"
                />
              </div>
            ))}
          </div>
        </div>

        {/* X Axis */}
        <div className="mt-3 flex">
          {/* This helps align the month labels properly */}
          <div
            aria-hidden
            className="mr-4 flex flex-col justify-between text-right font-mono text-[0.625rem] leading-none text-transparent">
            0
          </div>
          <div className="flex w-full justify-between space-x-2 lg:space-x-4 xl:space-x-8">
            {pastMonths.map((month) => (
              <div
                key={month}
                data-testid="active-users-x-axis-month"
                className="w-7 text-center font-mono text-[0.625rem] leading-3 text-gray-600">
                {MonthAbbreviations[month]}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-4/12 space-y-3 p-6">
        <h2 className="flex items-center space-x-1 font-semibold text-gray-900">
          <span>Feature usage</span>
          <Tooltip content={`Active users by features in ${activeMonth}.`}>
            <button>
              <InfoIcon className="h-4 w-4 text-gray-400" />
            </button>
          </Tooltip>
        </h2>
        <div className="space-y-1.5">
          <div className="flex items-center justify-between space-x-2">
            <h3 className="text-xs text-gray-500">Feature</h3>
            <h3 className="text-xs text-gray-500">Active Users</h3>
          </div>
          <div className="space-y-2.5">
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">CMA</div>
                <div
                  className="h-1 animate-pulse rounded-full bg-gray-200 transition-all"
                  style={{
                    width: `${fakeReportStats.current[0]}%`
                  }}
                />
              </div>
              <div
                aria-hidden
                className="flex-shrink-0 animate-pulse rounded bg-gray-200 text-xs leading-none text-transparent">
                0
              </div>
            </div>
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">Buyer Tour</div>
                <div
                  className="h-1 animate-pulse rounded-full bg-gray-200 transition-all"
                  style={{
                    width: `${fakeReportStats.current[1]}%`
                  }}
                />
              </div>
              <div
                aria-hidden
                className="flex-shrink-0 animate-pulse rounded bg-gray-200 text-xs leading-none text-transparent">
                0
              </div>
            </div>
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">Property Report</div>
                <div
                  className="h-1 animate-pulse rounded-full bg-gray-200 transition-all"
                  style={{
                    width: `${fakeReportStats.current[2]}%`
                  }}
                />
              </div>
              <div
                aria-hidden
                className="flex-shrink-0 animate-pulse rounded bg-gray-200 text-xs leading-none text-transparent">
                0
              </div>
            </div>
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">Flyer</div>
                <div
                  className="h-1 animate-pulse rounded-full bg-gray-200 transition-all"
                  style={{
                    width: `${fakeReportStats.current[3]}%`
                  }}
                />
              </div>
              <div
                aria-hidden
                className="flex-shrink-0 animate-pulse rounded bg-gray-200 text-xs leading-none text-transparent">
                0
              </div>
            </div>
            <div className="flex items-end space-x-1.5">
              <div className="w-full space-y-0.5">
                <div className="text-xs leading-4">Homebeat</div>
                <div
                  className="h-1 animate-pulse rounded-full bg-gray-200 transition-all"
                  style={{
                    width: `${fakeReportStats.current[4]}%`
                  }}
                />
              </div>
              <div
                aria-hidden
                className="flex-shrink-0 animate-pulse rounded bg-gray-200 text-xs leading-none text-transparent">
                0
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
