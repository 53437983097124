import { Notifier } from '@airbrake/browser'
import {
  differenceInMonths,
  format,
  formatDistanceToNowStrict,
  isSameDay,
  isSameMonth,
  isSameYear
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import numeral from 'numeral'

const ianaTimeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone

export function classNames(...classNames: (string | object)[]) {
  return classNames
    .reduce<string[]>((classes, clazz) => {
      if (typeof clazz === 'string') return [...classes, clazz]
      if (typeof clazz === 'object')
        return [
          ...classes,
          ...Object.entries(clazz)
            .filter(([, value]) => !!value)
            .map(([key]) => key)
        ]
      return classes
    }, [])
    .join(' ')
}

export function copyTextToClipboard(text: string) {
  window.navigator.clipboard.writeText(text)
}

export function getDateWithoutTimezone(dateValue?: string | number | Date) {
  const utcTimeZone = 'UTC'
  if (!dateValue) return utcToZonedTime(new Date(), utcTimeZone)

  return utcToZonedTime(new Date(dateValue), utcTimeZone)
}

export function getDateInTimeZone(dateValue: number | Date) {
  if (!dateValue) return dateValue
  return utcToZonedTime(dateValue, ianaTimeZone)
}

export function getTimeAgo(dateValue: string | number | Date) {
  if (!dateValue) return dateValue
  if (Math.abs(differenceInMonths(new Date(dateValue), new Date())) >= 1) {
    return formatDate(dateValue, 'MM/dd/yyyy')
  }
  const timeAgo = formatDistanceToNowStrict(
    getDateInTimeZone(new Date(dateValue))
  )
  return timeAgo.includes('seconds') ? 'just now' : `${timeAgo} ago`
}

export function formatDate(
  dateValue: string | number | Date,
  dateFormat: string
) {
  return format(getDateInTimeZone(new Date(dateValue)), dateFormat)
}

export function formatNumber(amount: string | number, format = '0,0') {
  return numeral(amount).format(format)
}

export function formatCurrency(amount: string | number, format = '$0[,]0') {
  return formatNumber(amount, format)
}

export function formatCurrencyShort(value: string | number) {
  let formattedValue = numeral(value).format('0.0a')
  const [leftDigits] = formattedValue.split('.')
  if (leftDigits.length === 1) {
    formattedValue = numeral(value).format('$0.[00]a')
  } else if (leftDigits.length === 2) {
    formattedValue = numeral(value).format('$0.[0]a')
  } else if (leftDigits.length >= 3) {
    formattedValue = numeral(value).format('$0a')
  }
  return formattedValue.toUpperCase()
}

// A helper for react router v6 active classNames
// This replaces the `activeClassName` prop which was removed
export function activeClassName(
  activeClassName: string,
  inactiveClassName?: string,
  additionalClassNames?: string
) {
  return ({ isActive }: { isActive: boolean }) =>
    classNames(additionalClassNames || '', {
      [activeClassName]: isActive,
      [inactiveClassName || '']: !isActive
    })
}

export function generateKey() {
  return Math.random().toString(16).substr(2)
}

export const SUPPORTED_IMAGE_FORMATS = [
  'image/png',
  'image/x-png',
  'image/gif',
  'image/jpg',
  'image/jpeg'
]

export function isCorrectFileSize(files: FileList | null) {
  if (!files?.length) return true
  return [...files].some((file) => file.size / 1024 / 1024 <= 5)
}

export function isSupportedFileType(files: FileList | null) {
  if (!files?.length) return true
  return ![...files].some(
    (file) => !SUPPORTED_IMAGE_FORMATS.includes(file.type)
  )
}

const airbrakeProjectId = Number(process.env.VITE_APP_AIRBRAKE_PROJECT_ID)
const airbrakeProjectKey = process.env.VITE_APP_AIRBRAKE_PROJECT_KEY

export const airbrake =
  airbrakeProjectId && airbrakeProjectKey
    ? new Notifier({
        projectId: airbrakeProjectId,
        projectKey: airbrakeProjectKey,
        environment: process.env.NODE_ENV
      })
    : undefined

export const states = [
  // USA
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District Of Columbia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' },

  // USA Outlying Territories
  { value: 'AS', name: 'American Samoa' },
  { value: 'GU', name: 'Guam' },
  { value: 'MP', name: 'Northern Mariana Islands' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'UM', name: 'United States Minor Outlying Islands' },
  { value: 'VI', name: 'Virgin Islands' },

  // Canada
  { value: 'AB', name: 'Alberta' },
  { value: 'BC', name: 'British Columbia' },
  { value: 'MB', name: 'Manitoba' },
  { value: 'NB', name: 'New Brunswick' },
  { value: 'NL', name: 'Newfoundland and Labrador' },
  { value: 'NS', name: 'Nova Scotia' },
  { value: 'ON', name: 'Ontario' },
  { value: 'PE', name: 'Prince Edward Island' },
  { value: 'QC', name: 'Quebec' },
  { value: 'SK', name: 'Saskatchewan' },
  { value: 'NT', name: 'Northwest Territories' },
  { value: 'NU', name: 'Nunavut' },
  { value: 'YT', name: 'Yukon' }
]

export const statesByValue = states.reduce<
  Record<string, { value: string; name: string }>
>((byValue, state) => ({ ...byValue, [state.value]: state }), {})

export const statesByName = states.reduce<
  Record<string, { value: string; name: string }>
>((byValue, state) => ({ ...byValue, [state.name]: state }), {})

export function getDateFromTime(
  time: string,
  date: string | Date = new Date()
) {
  let _date = new Date(date)

  const isTime =
    typeof time === 'string' &&
    time.includes(':') &&
    new Date(time).toString() === 'Invalid Date'
  const _time = isTime ? time : getTimeFromDate(time)

  if (!_time) return

  const [hours, minutes] = _time.split(':')

  _date = new Date(_date.setHours(Number(hours.padStart(2, '0'))))
  _date = new Date(_date.setMinutes(Number(minutes.padStart(2, '0'))))

  return new Date(_date)
}

export function getTimeFromDate(date?: string | Date) {
  if (!date) return

  const isTime =
    typeof date === 'string' &&
    date.includes(':') &&
    new Date(date).toString() === 'Invalid Date'
  const _date = isTime ? getDateFromTime(date) : new Date(date)

  if (!_date || _date.toString() === 'Invalid Date') return

  return `${String(_date.getHours()).padStart(2, '0')}:${String(
    _date.getMinutes()
  ).padStart(2, '0')}`
}

export function formatTime(time: string) {
  const date = getDateFromTime(time)

  if (!date) return ''

  const hours = date.getHours()
  const minutes = date.getMinutes()
  const period = hours >= 12 ? 'pm' : 'am'

  return `${hours % 12 || 12}:${`${minutes}`.padStart(2, '0')}${period}`
}

export function isSameDayMonthAndYear(
  dateA?: string | Date,
  dateB?: string | Date
) {
  if (!dateA || !dateB) return false

  const _dateA = new Date(dateA)
  const _dateB = new Date(dateB)

  return (
    isSameDay(_dateA, _dateB) &&
    isSameMonth(_dateA, _dateB) &&
    isSameYear(_dateA, _dateB)
  )
}

interface GoogleAddressArgs {
  address?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
}

export function getGoogleAddress({
  address,
  city,
  state,
  zip
}: GoogleAddressArgs) {
  return [
    address?.replaceAll(' ', '+'),
    city?.replaceAll(' ', '+'),
    [state?.replaceAll(' ', '+'), zip?.replaceAll(' ', '+')]
      .filter(Boolean)
      .join('+')
  ]
    .filter(Boolean)
    .join(',+')
}
