import { classNames, Modal } from '@cma/common'
import { Select, SelectFont } from '@cma/generated/graphql'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { CustomizeButton } from './CustomizeButton'

interface CustomizeFontProps {
  font?: Select
  fonts?: SelectFont[]
  loading?: boolean
  error?: string
  onUpdate: (option: Select) => void
}

export function CustomizeFont({
  font,
  fonts,
  loading,
  error,
  onUpdate
}: CustomizeFontProps) {
  const [showModal, setShowModal] = useState(false)

  if ((fonts?.length || 0) <= 1) return null

  return (
    <>
      <CustomizeButton
        error={error}
        loading={loading}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M9.93 13.5h4.14L12 7.98zM20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-4.05 16.5-1.14-3H9.17l-1.12 3H5.96l5.11-13h1.86l5.11 13h-2.09z" />
          </svg>
        }
        title="Font"
        subtitle={font?.value || 'Default Font'}
        onClick={() => setShowModal(true)}
      />
      <Modal size="xl" isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Title>Font</Modal.Title>
        <div className="mt-2 space-y-2">
          <button
            className="helix-text-brand text-sm"
            onClick={() => {
              setShowModal(false)
              onUpdate({ key: 'default', value: 'Default Font' })
            }}>
            Use theme default
          </button>
          <div className="-mb-6 -mr-6 max-h-[50vh] space-y-3 overflow-y-auto pb-6 pr-6">
            {fonts?.map((_font) => {
              const isActive = _font.key === font?.key

              return (
                <button
                  key={_font.key}
                  className={classNames('rounded-md border-2 p-8 text-left', {
                    'border-gray-100': !isActive,
                    'border-brand-primary': isActive
                  })}
                  style={{ fontFamily: _font.key }}
                  onClick={() => {
                    setShowModal(false)
                    onUpdate(_font)
                  }}>
                  {_font.url && (
                    <Helmet>
                      <link rel="stylesheet" href={_font.url} />
                    </Helmet>
                  )}
                  <div className="space-y-2">
                    <div className="helix-text-brand text-xl">
                      {_font.value}
                    </div>
                    <div className="text-gray-500">
                      A comparative market analysis (CMA) is an evaluation of a
                      home's value based on similar, recently sold homes in the
                      same neighborhood.
                    </div>
                  </div>
                </button>
              )
            })}
          </div>
        </div>
      </Modal>
    </>
  )
}
