import {
  Braintree,
  Button,
  Flash,
  FormField,
  Input,
  Link,
  Radio,
  Spinner
} from '@cma/common'
import { getAccessTokens } from '@cma/features/auth'
import {
  BillingSubscription,
  SettingsCard,
  useBilling,
  useUpdatePlan
} from '@cma/features/settings'
import { ChangeEvent, Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate } from 'react-router-dom'

function BillingPayments() {
  const [promoCode, setPromoCode] = useState('')
  const { data: billing } = useBilling()
  const subscriptionPlanId =
    billing?.account?.subscription?.subscriptionPlan?.id
  const [planId, setPlanId] = useState(subscriptionPlanId)
  const { mutate: updatePlan, isLoading, isSuccess, error } = useUpdatePlan()
  const [getToken, setGetToken] = useState<() => Promise<{ nonce: string }>>()
  const [btError, setBtError] = useState<any>()
  const [isWaitingForNonce, setIsWaitingForNonce] = useState(false)
  const accessTokens = getAccessTokens()
  const isImpersonating = !!accessTokens.impersonateToken

  useEffect(() => {
    setPlanId(subscriptionPlanId)
  }, [subscriptionPlanId])

  const canEditCard =
    (!billing?.user?.isCasUser && !billing?.account?.isSmallBroker) ||
    (billing?.user?.isAccountAdmin && billing?.account?.isSmallBroker)

  if (
    !isImpersonating &&
    billing?.user?.lwaId &&
    !(billing?.user?.isAccountAdmin && billing?.account?.isSmallBroker)
  ) {
    return <Navigate to="/settings" />
  }

  return (
    <SettingsCard>
      <SettingsCard.Group>
        <SettingsCard.Title>Update Plan</SettingsCard.Title>
        <BillingSubscription />
        <p className="text-center text-sm">
          <Link to="/settings/billing" className="helix-text-brand">
            Back to billing
          </Link>
        </p>
      </SettingsCard.Group>
      <SettingsCard.Group>
        <SettingsCard.Title>Plans</SettingsCard.Title>
        <div className="flex justify-center">
          <div data-testid="plans">
            {!billing?.user?.eligiblePlans?.length && (
              <p className="text-center text-sm text-gray-500">
                No plans to display
              </p>
            )}
            {billing?.user?.eligiblePlans?.map((plan) => (
              <div key={plan.id}>
                <Radio
                  checked={plan.id === planId}
                  onChange={() => setPlanId(plan.id)}>
                  {plan.label}
                </Radio>
              </div>
            ))}
          </div>
        </div>
      </SettingsCard.Group>
      {canEditCard && (
        <SettingsCard.Group>
          <SettingsCard.Title>Credit Card Info</SettingsCard.Title>
          <Braintree
            onInit={(getToken) => setGetToken(() => getToken)}
            onError={setBtError}>
            <form
              className="grid grid-cols-12 gap-5"
              onSubmit={async (e) => {
                e.preventDefault()
                setBtError(undefined)
                setIsWaitingForNonce(true)
                const { nonce } = (await getToken?.()) || { nonce: undefined }
                setIsWaitingForNonce(false)
                updatePlan({ planId, discountCode: promoCode, nonce })
              }}>
              <div className="col-span-12 grid grid-cols-12 gap-5">
                <div className="col-span-12 lg:col-span-6">
                  <FormField
                    label="Name on card"
                    required
                    error={
                      btError?.details?.invalidFieldKeys?.includes(
                        'cardholderName'
                      )
                        ? 'Invalid name'
                        : undefined
                    }>
                    <Braintree.Field
                      type="cardholderName"
                      placeholder="John Doe"
                    />
                  </FormField>
                </div>
                <div className="hidden lg:col-span-6 lg:block" />
              </div>
              <div className="col-span-12 lg:col-span-6">
                <FormField
                  label="Card number"
                  required
                  error={
                    btError?.details?.invalidFieldKeys?.includes('number')
                      ? 'Invalid card number'
                      : undefined
                  }>
                  <Braintree.Field
                    type="number"
                    placeholder="5555 5555 5555 5555"
                  />
                </FormField>
              </div>
              <div className="col-span-12 lg:col-span-3">
                <FormField
                  required
                  label="Card expiration"
                  error={
                    btError?.details?.invalidFieldKeys?.includes(
                      'expirationDate'
                    )
                      ? 'Invalid Expiration'
                      : undefined
                  }>
                  <Braintree.Field
                    type="expirationDate"
                    placeholder={`12 / ${new Date().getUTCFullYear()}`}
                  />
                </FormField>
              </div>
              <div data-testid="cvv" className="col-span-12 lg:col-span-3">
                <FormField
                  required
                  label="Card CVV"
                  error={
                    btError?.details?.invalidFieldKeys?.includes('cvv')
                      ? 'Invalid CVV'
                      : undefined
                  }>
                  <Braintree.Field type="cvv" placeholder="123" />
                </FormField>
              </div>
              <div className="col-span-12 lg:col-span-3">
                <FormField
                  required
                  label="Postal Code"
                  error={
                    btError?.details?.invalidFieldKeys?.includes('postalCode')
                      ? 'Invalid postal code'
                      : undefined
                  }>
                  <Braintree.Field type="postalCode" placeholder="12345" />
                </FormField>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <FormField label="Promo Code">
                  <Input
                    value={promoCode}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPromoCode(e.target.value)
                    }
                  />
                </FormField>
              </div>
              <div className="col-span-12">
                <Button
                  fullWidth
                  loading={
                    (isLoading || isWaitingForNonce) && !btError && !error
                  }>
                  Update Plan
                </Button>
              </div>
              {(btError || error) && (
                <div className="col-span-12">
                  <Flash variant="error">
                    {btError?.message || error?.message}
                  </Flash>
                </div>
              )}
              {isSuccess && (
                <div className="col-span-12">
                  <Flash variant="success">Plan Updated!</Flash>
                </div>
              )}
            </form>
          </Braintree>
        </SettingsCard.Group>
      )}
    </SettingsCard>
  )
}

export default function BillingPaymentsProvider() {
  return (
    <>
      <Helmet>
        <title>Update Plan - Billing - Account Settings - Cloud CMA</title>
      </Helmet>
      <Suspense
        fallback={
          <SettingsCard>
            <SettingsCard.Group>
              <SettingsCard.Title>Billing</SettingsCard.Title>
              <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                <div className="h-4 w-4">
                  <Spinner />
                </div>
                <div>Loading Billing Information</div>
              </div>
            </SettingsCard.Group>
          </SettingsCard>
        }>
        <BillingPayments />
      </Suspense>
    </>
  )
}
