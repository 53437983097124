import { classNames } from '@cma/common'

interface TourButtonProps
  extends Omit<JSX.IntrinsicElements['button'], 'className'> {
  variant?: 'primary' | 'secondary'
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
}

export function TourButton({
  variant = 'primary',
  leftIcon,
  rightIcon,
  children,
  ...props
}: TourButtonProps) {
  return (
    <button
      {...props}
      className={classNames(
        'flex w-full items-center justify-center space-x-1.5 rounded-md px-2 py-1 text-sm font-semibold',
        {
          'bg-gray-200 text-gray-600': variant === 'primary',
          'helix-text-brand bg-gray-200': variant === 'secondary'
        }
      )}>
      {leftIcon && (
        <span className="-ml-1 mr-2 h-5 w-5 flex-shrink-0">{leftIcon}</span>
      )}
      {children}
      {rightIcon && (
        <span className="-mr-1 ml-2 h-5 w-5 flex-shrink-0">{rightIcon}</span>
      )}
    </button>
  )
}
