import { useProductAvailability } from '@cma/app'
import { Link, NotFound, Wrapper } from '@cma/common'
import {
  ReportCardPreview,
  ReportHeader,
  ReportPublishAlerts,
  ReportPublishShare,
  useReport
} from '@cma/features/report'
import { ChevronLeftIcon } from '@cma/icons'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

export default function FlyerPublish() {
  const { id } = useParams()
  const { data: { report } = {} } = useReport({ id: id as string })
  const { data: availability } = useProductAvailability()

  if (!report) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  const streams = availability?.products.find(
    (product) => product.key === 'cloud_streams'
  )
  const showStreamsAlert =
    report?.__typename !== 'CmaReport' && streams?.subscribed

  return (
    <Wrapper>
      <Helmet>
        <title>Publish Flyer - Flyer - Cloud CMA</title>
      </Helmet>
      <ReportHeader
        reportId={report.id}
        title="Publish Flyer"
        breadcrumbs={[
          { title: 'Criteria', url: `/flyers/${id}/edit` },
          { title: 'Listings', url: `/flyers/${id}` },
          { title: 'Customize', url: `/flyers/${id}/customize` },
          { title: 'Publish', url: `/flyers/${id}/publish`, publish: true }
        ]}
      />
      <main>
        <div className="flex w-full flex-col overflow-hidden rounded-lg border bg-white lg:flex-row">
          <div className="border-b p-8 lg:border-b-0 lg:border-r">
            <div className="w-full space-y-6 lg:w-[27rem]">
              <div className="space-y-4">
                <h1 className="text-xl font-medium text-gray-500">
                  Your Flyer is ready
                </h1>
                <ReportCardPreview
                  report={report}
                  showPdfReport
                  showLiveReport={false}
                  showSlideshowReport={false}
                />
              </div>
              {showStreamsAlert && (
                <div className="space-y-2">
                  <h2 className="font-medium text-gray-500">
                    Sharing options:
                  </h2>
                  <ReportPublishShare report={report} />
                </div>
              )}
              <Link
                to="/flyers"
                className="helix-text-brand inline-flex items-center space-x-1 text-sm font-medium">
                <ChevronLeftIcon className="h-8 w-8" />
                <span>Back to Flyers</span>
              </Link>
            </div>
          </div>
          <div className="grow p-8">
            {showStreamsAlert && <ReportPublishAlerts report={report} />}
            {!showStreamsAlert && (
              <div className="w-full space-y-4 xl:w-[27rem]">
                <h2 className="text-xl font-medium text-gray-500">
                  Sharing options:
                </h2>
                <ReportPublishShare report={report} />
              </div>
            )}
          </div>
        </div>
      </main>
    </Wrapper>
  )
}
