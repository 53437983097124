import { useCurrentUser } from '@cma/app'
import { Button, FormField, Input, Link } from '@cma/common'
import { useLogin } from '@cma/features/auth'
import { CloudIcon } from '@cma/icons'
import { HelixIcon } from '@helix/helix-icon'
import { wolfie } from '@helix/helix-icon/outlined'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object({
  email: yup.string().email().required('Your email is required'),
  password: yup.string().required('Your password is required')
})

interface LocationState {
  from?: {
    pathname?: string
  }
}

export default function Login() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const navigate = useNavigate()
  const location = useLocation()
  const { mutate: login, isLoading, error } = useLogin()
  const redirectTo =
    (location.state as LocationState | null)?.from?.pathname || '/'

  if (currentUser) {
    return <Navigate to={redirectTo} />
  }

  return (
    <>
      <div className="space-y-4 rounded-lg bg-white px-8 py-6 shadow">
        <h1 className="mb-6 text-center text-2xl font-medium">Welcome!</h1>
        <a
          className="hover:no-underline"
          href={`${process.env.VITE_APP_LW_PLATFORM_URL}/product/launch?a=cc`}>
          <button className="helix-btn helix-btn--primary helix-btn--medium helix-d-flex w-full">
            <span className="helix-ml-n2 mr-6">
              <HelixIcon icon={wolfie} className="h-6 w-6" />
            </span>
            <span className="m-auto">Sign in with Lone Wolf Account</span>
          </button>
        </a>
        <a
          href={`${process.env.VITE_APP_CAS_URL}/app/external_login/cloud_cma?continue_to=${process.env.VITE_APP_CMA_APP_URL}${redirectTo}`}
          className="flex items-center hover:no-underline">
          <span className="flex h-9 w-9 flex-shrink-0 items-center justify-center rounded-bl rounded-tl bg-suite-600 text-white">
            <CloudIcon className="h-5 w-5" />
          </span>
          <span className="flex h-9 w-full items-center justify-center rounded-br rounded-tr !border border-l-0 !border-solid border-suite-600 px-2 text-center text-sm font-medium text-suite-600">
            Sign in with Cloud Agent Suite
          </span>
        </a>
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true">
            <div className="h-0.5 w-full bg-gray-200" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-xs font-medium uppercase text-gray-500">
              Or
            </span>
          </div>
        </div>
        <form
          className="space-y-4"
          onSubmit={handleSubmit((data) =>
            login(data, {
              onSuccess() {
                // Redirect back to intended page or to the home page
                navigate((location?.state as { from?: string })?.from ?? '/')
              }
            })
          )}>
          <FormField label="Email" error={errors.email?.message}>
            <Input {...register('email')} type="email" />
          </FormField>
          <FormField label="Password" error={errors.password?.message}>
            <Input {...register('password')} type="password" />
          </FormField>
          <div className="flex justify-center">
            <Button loading={isLoading}>Sign In</Button>
          </div>
          {!!error && (
            <div className="text-center text-sm text-red-500">
              {error.message}
            </div>
          )}
        </form>
      </div>
      <div className="flex">
        <Link
          to="/password/new"
          className="helix-text-brand mx-auto text-xs font-medium hover:no-underline">
          Forgot password?
        </Link>
      </div>
    </>
  )
}
