import { useCurrentUser } from '@cma/app'
import { Avatar, classNames } from '@cma/common'
import { ArrowLeftLongIcon, CloudIcon } from '@cma/icons'

interface TourHeaderProps {
  title?: string | null
  agentName?: string | null
  agentPhone?: string | null
  agentAvatar?: string | null
  companyLogo?: string | null
  onBackClick?: () => void
}

export function TourHeader({
  onBackClick,
  title,
  agentAvatar,
  agentName,
  agentPhone,
  companyLogo
}: TourHeaderProps) {
  const { data: { currentUser } = {} } = useCurrentUser()
  return (
    <nav className="sticky top-0 z-[1] flex h-[3.75rem] shrink-0 items-center justify-between gap-2 bg-white px-3 py-6 shadow sm:gap-4 sm:p-8">
      <div className="inline-flex w-1/2 sm:w-1/3">
        {onBackClick ? (
          <button
            aria-label="Back"
            onClick={onBackClick}
            className={classNames(
              'helix-text-brand flex items-center space-x-4 text-sm',
              {
                'cursor-pointer': !!onBackClick
              }
            )}>
            <ArrowLeftLongIcon className="h-5 w-5 shrink-0" />
            {companyLogo ? (
              <img
                src={companyLogo}
                className="max-h-10 object-cover"
                alt="Company Logo"
              />
            ) : (
              <span>Back</span>
            )}
          </button>
        ) : !currentUser?.id ? (
          <CloudIcon className="h-8 w-8 text-cma-500" aria-label="Logo" />
        ) : (
          <a href={`${process.env.VITE_APP_CMA_URL}/tours`}>
            <CloudIcon className="h-8 w-8 text-cma-500" aria-label="Logo" />
          </a>
        )}
      </div>
      <div className="hidden w-1/3 items-center justify-center sm:flex">
        <div
          className="truncate text-center text-xl font-semibold text-gray-900"
          title={title || ''}>
          {title}
        </div>
      </div>
      <div className="flex w-1/2 items-center justify-end gap-3 sm:w-1/3">
        {(agentName || agentPhone || agentAvatar) && (
          <>
            {(agentName || agentPhone) && (
              <div className="flex min-w-0 flex-col gap-0.5 text-right">
                {agentName && (
                  <h3
                    className="truncate text-sm font-semibold text-gray-900"
                    title={agentName}>
                    {agentName}
                  </h3>
                )}
                {agentPhone && (
                  <p
                    className="truncate text-xs text-gray-500"
                    title={agentPhone}>
                    {agentPhone}
                  </p>
                )}
              </div>
            )}
            <Avatar name={agentName} src={agentAvatar} size="sm" />
          </>
        )}
      </div>
    </nav>
  )
}
