import { ForwardedRef, forwardRef } from 'react'
import { classNames } from './utils'

interface RadioProps
  extends Omit<JSX.IntrinsicElements['input'], 'className' | 'type' | 'size'> {
  variant?: 'primary' | 'danger'
  size?: 'md' | 'lg'
}

export const Radio = forwardRef(function Radio(
  {
    variant = 'primary',
    size = 'md',
    disabled,
    checked,
    children,
    ...props
  }: RadioProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <label
      className={classNames('inline-flex items-center space-x-2', {
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled
      })}>
      <span className="relative inline-flex">
        <input
          ref={ref}
          type="radio"
          {...props}
          disabled={disabled}
          checked={checked}
          className={classNames(
            'peer appearance-none rounded-full border-2 outline-none focus:ring-2 focus:ring-offset-2',
            {
              'border-gray-300 checked:border-brand-primary focus:ring-brand-primary':
                variant === 'primary',
              'border-red-300 checked:border-red-500 checked:bg-red-500 focus:ring-red-500':
                variant === 'danger',

              'h-4 w-4': size === 'md',
              'h-5 w-5': size === 'lg',

              'bg-gray-50': disabled
            }
          )}
        />
        <span
          className={classNames(
            'absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 rounded-full peer-checked:flex',
            {
              'bg-brand-primary': variant === 'primary',
              'bg-red-500': variant === 'danger',

              'h-1.5 w-1.5': size === 'md',
              'h-3 w-3': size === 'lg'
            }
          )}
        />
      </span>
      {!!children && (
        <span
          className={classNames('font-medium text-gray-500', {
            'text-xs': size === 'md',
            'text-sm': size === 'lg'
          })}>
          {children}
        </span>
      )}
    </label>
  )
})
