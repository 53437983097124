import {
  Button,
  Checkbox,
  classNames,
  FormField,
  Input,
  Label,
  NotFound,
  Select,
  Wrapper
} from '@cma/common'
import { ReportHeader, useReport } from '@cma/features/report'
import { Disclosure } from '@headlessui/react'
import { PhotographIcon, TrashIcon } from '@heroicons/react/outline'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  LockClosedIcon
} from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useMatch, useParams } from 'react-router-dom'
import * as yup from 'yup'

const lazyNumber = (error: string) => (value: string | number | boolean) => {
  return value === '' ? yup.string() : yup.number().typeError(error)
}

const schema = yup.object({
  client: yup.string().required('The client is required'),
  notes: yup.string(),
  bedsMin: yup.lazy(lazyNumber('Min beds must be a valid number')),
  bedsMax: yup.lazy(lazyNumber('Max beds must be a valid number')),
  bathsMin: yup.lazy(lazyNumber('Min baths must be a valid number')),
  bathsMax: yup.lazy(lazyNumber('Max baths must be a valid number')),
  sqftMin: yup.lazy(lazyNumber('Min sqft must be a valid number')),
  sqftMax: yup.lazy(lazyNumber('Max sqft must be a valid number')),
  lotsizeMin: yup.lazy(lazyNumber('Min lot size must be a valid number')),
  lotsizeMax: yup.lazy(lazyNumber('Max lot size must be a valid number')),
  priceListMin: yup.lazy(lazyNumber('Min list price must be a valid number')),
  priceListMax: yup.lazy(lazyNumber('Max list price must be a valid number')),
  mlsNumbers: yup.string(),
  proximitySearch: yup.boolean(),
  minListings: yup.lazy(
    lazyNumber('Number of listings must be a valid number')
  ),
  monthsBack: yup.lazy(lazyNumber('Off marker months must be a valid number')),
  address: yup.string().required('The address is required'),
  beds: yup.lazy(lazyNumber('Beds must be a valid number')),
  baths: yup.lazy(lazyNumber('Baths must be a valid number')),
  sqft: yup.lazy(lazyNumber('sqft must be a valid number')),
  propType: yup.string(),
  propSubType: yup.string(),
  photo: yup.string(),
  lotSize: yup.lazy(lazyNumber('Lot size must be a valid number')),
  landMeasurement: yup.string(),
  yearBuilt: yup.lazy(lazyNumber('Year built must be a valid number')),
  taxes: yup.lazy(lazyNumber('Taxes must be a valid number')),
  garages: yup.lazy(lazyNumber('Garages must be a valid number')),
  area: yup.lazy(lazyNumber('Area must be a valid number')),
  subdivision: yup.string(),
  style: yup.string()
})

export default function CmaDetails() {
  const { id } = useParams()
  const { data: { report } = {} } = useReport({ id: id as string })
  const isEditingMatch = useMatch('/cmas/:id/edit')
  const isEditing = !!isEditingMatch
  const photoRef = useRef<HTMLInputElement>(null)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      client: report?.title ?? '',
      notes: report?.notes ?? '',
      bedsMin: report?.__typename === 'CmaReport' ? report?.bedsMin ?? '' : '',
      bedsMax: report?.__typename === 'CmaReport' ? report?.bedsMax ?? '' : '',
      bathsMin:
        report?.__typename === 'CmaReport' ? report?.bathsMin ?? '' : '',
      bathsMax:
        report?.__typename === 'CmaReport' ? report?.bathsMax ?? '' : '',
      sqftMin: report?.__typename === 'CmaReport' ? report?.sqftMin ?? '' : '',
      sqftMax: report?.__typename === 'CmaReport' ? report?.sqftMax ?? '' : '',
      lotsizeMin:
        report?.__typename === 'CmaReport' ? report?.lotsizeMin ?? '' : '',
      lotsizeMax:
        report?.__typename === 'CmaReport' ? report?.lotsizeMax ?? '' : '',
      priceListMin:
        report?.__typename === 'CmaReport' ? report?.priceListMin ?? '' : '',
      priceListMax:
        report?.__typename === 'CmaReport' ? report?.priceListMax ?? '' : '',
      proximitySearch:
        report?.__typename === 'CmaReport'
          ? report?.proximitySearch ?? false
          : false,
      // TODO: This should default to the config in forge
      minListings:
        report?.__typename === 'CmaReport' ? report?.minListings ?? 10 : 10,
      // TODO: This should default to the config in forge
      monthsBack:
        report?.__typename === 'CmaReport' ? report?.monthsBack ?? 6 : 6,
      address: report?.subjectProperty?.address ?? '',
      beds: report?.subjectProperty?.beds ?? '',
      baths: report?.subjectProperty?.baths ?? '',
      sqft: report?.subjectProperty?.sqft ?? '',
      // TODO: This should default to the config in forge
      propType: report?.subjectProperty?.propType ?? 'Residential',
      // TODO: This should default to the config in forge
      propSubType: report?.subjectProperty?.propSubType ?? '',
      photo: report?.subjectProperty?.photo ?? '',
      lotSize: report?.subjectProperty?.lotSize ?? '',
      landMeasurement: report?.subjectProperty?.landMeasurement ?? 'sqft',
      yearBuilt: report?.subjectProperty?.yearBuilt ?? '',
      taxes: report?.subjectProperty?.taxes ?? '',
      garages: report?.subjectProperty?.garages ?? '',
      area: report?.subjectProperty?.area ?? '',
      subdivision: report?.subjectProperty?.subdivision ?? '',
      style: report?.subjectProperty?.style ?? ''
    }
  })

  const hasAdvanceInfoError = Boolean(
    errors.lotSize ||
      errors.yearBuilt ||
      errors.taxes ||
      errors.garages ||
      errors.area ||
      errors.subdivision ||
      errors.style
  )
  const hasMoreCriteriaError = Boolean(
    errors.bedsMin ||
      errors.bedsMax ||
      errors.bathsMin ||
      errors.bathsMax ||
      errors.sqftMin ||
      errors.sqftMax ||
      errors.lotsizeMin ||
      errors.lotsizeMax ||
      errors.priceListMin ||
      errors.priceListMax
  )

  if (isEditing && !report) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <form onSubmit={handleSubmit((data) => console.log(data))}>
      <Wrapper>
        <Helmet>
          <title>
            {isEditing ? 'Edit Details' : 'Create Details'} - CMA Report - Cloud
            CMA
          </title>
        </Helmet>
        <ReportHeader
          reportId={report?.id || ''}
          title={isEditing ? 'Edit CMA Report' : 'Create CMA Report'}
          breadcrumbs={[
            {
              title: 'Criteria',
              url: isEditing ? `/cmas/${id}/edit` : '/cmas/new'
            },
            { title: 'Listings', url: `/cmas/${id}`, disabled: !isEditing },
            {
              title: 'Customize',
              url: `/cmas/${id}/customize`,
              disabled: !isEditing
            },
            {
              title: 'Publish',
              url: `/cmas/${id}/publish`,
              disabled: !isEditing,
              publish: true
            }
          ]}>
          <Button
            onClick={() => {
              console.log(
                isEditing
                  ? 'Updating listing and navigating to listings'
                  : 'Fetching listings and navigating to listing'
              )
            }}>
            {isEditing ? 'Update Listings' : 'Fetch Listings'}
          </Button>
        </ReportHeader>
        <main className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div className="col-span-2 space-y-4 rounded-lg bg-white p-5 shadow md:col-span-1">
              <h2 className="text-xl font-medium">Name the Report</h2>
              <div className="space-y-4">
                <FormField
                  label="Client"
                  required
                  error={errors.client?.message}>
                  <Input autoFocus {...register('client')} />
                </FormField>
                <FormField
                  label={
                    <span className="flex items-center space-x-1">
                      <span>Private Notes</span>
                      <LockClosedIcon className="h-3 w-3" />
                    </span>
                  }
                  tip="Private notes don't appear in the report"
                  error={errors.notes?.message}>
                  <Input
                    as="textarea"
                    rows={6}
                    maxLength={255}
                    {...register('notes')}
                  />
                </FormField>
              </div>
            </div>
            <div className="col-span-2 space-y-4 rounded-lg bg-white p-5 shadow md:col-span-1">
              <h2 className="text-xl font-medium">Subject Property</h2>
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4">
                  <FormField
                    label="Address"
                    required
                    error={errors.address?.message}>
                    <Input {...register('address')} />
                  </FormField>
                </div>
                <FormField label="Beds" error={errors.beds?.message}>
                  <Input {...register('beds')} />
                </FormField>
                <FormField label="Baths" error={errors.baths?.message}>
                  <Input {...register('baths')} />
                </FormField>
                <div className="col-span-2">
                  <FormField label="Sq Ft" error={errors.sqft?.message}>
                    <Input {...register('sqft')} />
                  </FormField>
                </div>
                <div className="col-span-2 grid gap-4">
                  <FormField
                    label="Property Type"
                    error={errors.propType?.message}>
                    {/* TODO: This to come from forge */}
                    <Select {...register('propType')}>
                      <option value="Business Opportunity">
                        Business Opportunity
                      </option>
                      <option value="Commercial Lease">Commercial Lease</option>
                      <option value="Commercial Sale">Commercial Sale</option>
                      <option value="Land">Land</option>
                      <option value="Manufactured In Park">
                        Manufactured In Park
                      </option>
                      <option value="Residential">Residential</option>
                      <option value="Residential Income">
                        Residential Income
                      </option>
                      <option value="Residential Lease">
                        Residential Lease
                      </option>
                    </Select>
                  </FormField>
                  <FormField
                    label="Property Sub Type"
                    error={errors.propSubType?.message}>
                    {/* TODO: This to come from forge */}
                    <Select {...register('propSubType')}>
                      <option value="">None</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Apartment">Apartment</option>
                      <option value="Boat Slip">Boat Slip</option>
                      <option value="Business">Business</option>
                      <option value="Cabin">Cabin</option>
                      <option value="Commercial/Residential">
                        Commercial/Residential
                      </option>
                      <option value="Condominium">Condominium</option>
                      <option value="Deeded Parking">Deeded Parking</option>
                      <option value="Duplex">Duplex</option>
                      <option value="Farm">Farm</option>
                      <option value="High Tech-Flex">High Tech-Flex</option>
                      <option value="Hotel/Motel">Hotel/Motel</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Loft">Loft</option>
                      <option value="Manufactured Home">
                        Manufactured Home
                      </option>
                      <option value="Manufactured On Land">
                        Manufactured On Land
                      </option>
                      <option value="Mixed Use">Mixed Use</option>
                      <option value="Mobile Home Park">Mobile Home Park</option>
                      <option value="Multi Family">Multi Family</option>
                      <option value="Office">Office</option>
                      <option value="Own Your Own">Own Your Own</option>
                      <option value="Quadruplex">Quadruplex</option>
                      <option value="Ranch">Ranch</option>
                      <option value="Retail">Retail</option>
                      <option value="Rooms for Rent">Rooms for Rent</option>
                      <option value="Single Family Residence">
                        Single Family Residence
                      </option>
                      <option value="Specialty">Specialty</option>
                      <option value="Stock Cooperative">
                        Stock Cooperative
                      </option>
                      <option value="Studio">Studio</option>
                      <option value="Timeshare">Timeshare</option>
                      <option value="Townhouse">Townhouse</option>
                      <option value="Triplex">Triplex</option>
                      <option value="Unimproved Land">Unimproved Land</option>
                      <option value="Warehouse">Warehouse</option>
                      <option value="Water Position With Land">
                        Water Position With Land
                      </option>
                      <option value="Water Position Without Land">
                        Water Position Without Land
                      </option>
                    </Select>
                  </FormField>
                </div>
                <div className="col-span-2">
                  <label className="relative flex h-full w-full cursor-pointer items-center justify-center overflow-hidden rounded-md border bg-gray-50 shadow-sm focus-within:border-brand-primary">
                    <input
                      type="file"
                      className="sr-only"
                      aria-hidden
                      ref={photoRef}
                    />
                    {report?.subjectProperty?.photo ? (
                      <>
                        <img
                          src={report.subjectProperty.photo}
                          alt={`${
                            report.subjectProperty.address || 'Subject Property'
                          } Cover`}
                          className="absolute h-full w-full object-cover"
                        />
                        <span className="absolute bottom-0 left-0 grid w-full grid-cols-2 bg-black bg-opacity-60 text-xs text-white">
                          <button
                            type="button"
                            className="p-2 text-right"
                            onClick={() => photoRef.current?.click()}>
                            Change
                          </button>
                          <button
                            type="button"
                            className="flex items-center space-x-1 p-2 text-left"
                            onClick={() => console.log('Deleting photo')}>
                            <TrashIcon className="h-5 w-4" />
                            <span>Delete</span>
                          </button>
                        </span>
                      </>
                    ) : (
                      <span className="helix-text-brand space-y-2">
                        <PhotographIcon className="mx-auto h-6 w-6" />
                        <span className="text-xs font-medium">
                          Add Cover Photo
                        </span>
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div>
                <Disclosure>
                  <Disclosure.Button className="flex w-full items-center space-x-1 rounded-md py-2 text-sm focus:outline-none focus:ring-2 focus:ring-brand-primary">
                    {({ open }) => (
                      <>
                        {open || hasAdvanceInfoError ? (
                          <ChevronDownIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
                        ) : (
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
                        )}
                        <span className="flex min-w-0 items-baseline space-x-2">
                          <span className="helix-text-brand flex-shrink-0">
                            Advanced Info
                          </span>
                          <span className="truncate text-xs text-gray-400">
                            Additional subject info used in four column layouts
                          </span>
                        </span>
                      </>
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel static={hasAdvanceInfoError}>
                    <div className="grid grid-cols-4 gap-4">
                      {/* One-off input + select. No reusable component needed at the moment. */}
                      <div className="col-span-2 lg:col-span-2">
                        <FormField
                          error={[
                            errors.lotSize?.message,
                            errors.landMeasurement?.message
                          ]}>
                          {({ variant, ...props }) => {
                            const lotSizeProps = errors.lotSize ? props : {}
                            const landMeasurementProps = errors.landMeasurement
                              ? props
                              : {}

                            return (
                              <div className="flex flex-col">
                                <Label htmlFor="lotSize">Lot Size</Label>
                                <div
                                  className={classNames(
                                    'relative mt-1 flex items-center rounded-md border border-solid border-gray-200 shadow-sm',
                                    {
                                      'border-gray-300 focus-within:border-brand-primary':
                                        variant !== 'danger',
                                      'border-red-300 focus-within:border-red-500':
                                        variant === 'danger'
                                    }
                                  )}>
                                  <input
                                    {...lotSizeProps}
                                    id="lotSize"
                                    autoFocus
                                    className="w-full max-w-[4.375rem] appearance-none rounded-bl-md rounded-tl-md p-2 focus:outline-none sm:text-sm"
                                    {...register('lotSize')}
                                  />
                                  <div className="relative flex-grow">
                                    <select
                                      className="w-full cursor-pointer appearance-none rounded-br-md rounded-tr-md border-l border-gray-300 bg-gray-100 p-2 pr-6 outline-none sm:text-sm"
                                      {...landMeasurementProps}
                                      {...register('landMeasurement')}>
                                      <option value="sqft">Square Feet</option>
                                      <option value="acres">Acres</option>
                                    </select>
                                    <ChevronDownIcon className="absolute right-1 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-500" />
                                  </div>
                                </div>
                              </div>
                            )
                          }}
                        </FormField>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <FormField
                          label="Year Blt."
                          error={errors.yearBuilt?.message}>
                          <Input {...register('yearBuilt')} />
                        </FormField>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <FormField label="Taxes" error={errors.taxes?.message}>
                          <Input {...register('taxes')} />
                        </FormField>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <FormField
                          label="Garages"
                          error={errors.garages?.message}>
                          <Input {...register('garages')} />
                        </FormField>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <FormField label="Area" error={errors.area?.message}>
                          <Input {...register('area')} />
                        </FormField>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <FormField
                          label="Subdivision"
                          error={errors.subdivision?.message}>
                          <Input {...register('subdivision')} />
                        </FormField>
                      </div>
                      <div className="col-span-2 lg:col-span-1">
                        <FormField label="Style" error={errors.style?.message}>
                          <Input {...register('style')} />
                        </FormField>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </Disclosure>
              </div>
            </div>
            <div className="col-span-2">
              <div className="space-y-4 rounded-lg bg-white p-5 shadow">
                <h2 className="text-center text-xl font-medium">
                  How do you want to get comparative listings?
                </h2>
                <div className="relative">
                  <div className="absolute left-1/2 hidden h-full -translate-x-1/2 items-center justify-center md:flex">
                    <div className="absolute h-full w-[0.0625rem] bg-gray-200" />
                    <div className="relative flex h-8 w-8 items-center justify-center rounded-full border bg-white text-xs uppercase text-gray-500">
                      Or
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2 space-y-4 pr-0 md:col-span-1 md:pr-8">
                      <h3 className="text-large">Exact Listings</h3>
                      <FormField
                        label="By MLS Numbers"
                        error={errors.mlsNumbers?.message}>
                        <Input
                          as="textarea"
                          rows={6}
                          maxLength={255}
                          {...register('mlsNumbers')}
                        />
                      </FormField>
                      <div className="space-y-2">
                        <p className="text-xs text-gray-500">
                          Get exactly the listings you want by narrowing down
                          your comps on the MLS first.
                        </p>
                        <i className="inline-block text-xs text-gray-400">
                          Tip: Separate your MLS Numbers with commas, spaces, or
                          new lines.
                        </i>
                      </div>
                    </div>
                    <div className="col-span-2 space-y-4 pl-0 md:col-span-1 md:pl-8">
                      <h3 className="text-large">Quick and Dirty</h3>
                      <FormField
                        label="By Proximity"
                        error={errors.proximitySearch?.message}>
                        <Checkbox
                          defaultChecked={
                            report?.__typename === 'CmaReport'
                              ? !!report?.proximitySearch
                              : false
                          }
                          {...register('proximitySearch')}>
                          Automatically find listings near the Subject Property
                        </Checkbox>
                      </FormField>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2 sm:col-span-1">
                          <FormField
                            label="Number of Listings"
                            error={errors.minListings?.message}>
                            <Select {...register('minListings')}>
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={40}>40</option>
                              <option value={50}>50</option>
                            </Select>
                          </FormField>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <FormField
                            label="Off Market Timeline"
                            error={errors.monthsBack?.message}>
                            <Select {...register('monthsBack')}>
                              <option value={1}>1 month</option>
                              <option value={2}>2 months</option>
                              <option value={3}>3 months</option>
                              <option value={4}>4 months</option>
                              <option value={5}>5 months</option>
                              <option value={6}>6 months</option>
                              <option value={7}>7 months</option>
                              <option value={8}>8 months</option>
                              <option value={9}>9 months</option>
                              <option value={10}>10 months</option>
                              <option value={11}>11 months</option>
                              <option value={12}>1 year</option>
                              <option value={13}>13 months</option>
                              <option value={14}>14 months</option>
                              <option value={15}>15 months</option>
                              <option value={16}>16 months</option>
                              <option value={17}>17 months</option>
                              <option value={18}>18 months</option>
                              <option value={19}>19 months</option>
                              <option value={20}>20 months</option>
                              <option value={21}>21 months</option>
                              <option value={22}>22 months</option>
                              <option value={23}>23 months</option>
                              <option value={24}>2 years</option>
                              <option value={25}>25 months</option>
                              <option value={26}>26 months</option>
                              <option value={27}>27 months</option>
                              <option value={28}>28 months</option>
                              <option value={29}>29 months</option>
                              <option value={30}>30 months</option>
                              <option value={31}>31 months</option>
                              <option value={32}>32 months</option>
                              <option value={33}>33 months</option>
                              <option value={34}>34 months</option>
                              <option value={35}>35 months</option>
                              <option value={36}>3 years</option>
                            </Select>
                          </FormField>
                        </div>
                      </div>
                      <div>
                        <Disclosure>
                          <Disclosure.Button className="flex w-full items-center space-x-1 rounded-md py-2 text-sm focus:outline-none focus:ring-2 focus:ring-brand-primary">
                            {({ open }) => (
                              <>
                                {open || hasMoreCriteriaError ? (
                                  <ChevronDownIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
                                ) : (
                                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-500" />
                                )}
                                <span className="flex min-w-0 items-baseline space-x-2">
                                  <span className="helix-text-brand flex-shrink-0">
                                    More Criteria
                                  </span>
                                  <span className="truncate text-xs text-gray-400">
                                    Additional subject info
                                  </span>
                                </span>
                              </>
                            )}
                          </Disclosure.Button>
                          <Disclosure.Panel static={hasMoreCriteriaError}>
                            <div className="grid grid-cols-2 gap-4">
                              <div className="col-span-2 sm:col-span-1">
                                <FormField
                                  error={[
                                    errors.bedsMin?.message,
                                    errors.bedsMax?.message
                                  ]}>
                                  {(props) => {
                                    const minProps = errors.bedsMin ? props : {}
                                    const maxProps = errors.bedsMax ? props : {}

                                    return (
                                      <>
                                        <Label htmlFor="bedsMin">Beds</Label>
                                        <div className="mt-1 flex items-center space-x-2">
                                          <Input
                                            {...minProps}
                                            {...register('bedsMin')}
                                            id="bedsMin"
                                            autoFocus
                                          />
                                          <div>-</div>
                                          <Input
                                            {...maxProps}
                                            {...register('bedsMax')}
                                          />
                                        </div>
                                      </>
                                    )
                                  }}
                                </FormField>
                              </div>
                              <div className="col-span-2 sm:col-span-1">
                                <FormField
                                  error={[
                                    errors.bathsMin?.message,
                                    errors.bathsMax?.message
                                  ]}>
                                  {(props) => {
                                    const minProps = errors.bathsMin
                                      ? props
                                      : {}
                                    const maxProps = errors.bathsMax
                                      ? props
                                      : {}

                                    return (
                                      <>
                                        <Label htmlFor="bathsMin">Baths</Label>
                                        <div className="mt-1 flex items-center space-x-2">
                                          <Input
                                            {...minProps}
                                            {...register('bathsMin')}
                                            id="bathsMin"
                                          />
                                          <div>-</div>
                                          <Input
                                            {...maxProps}
                                            {...register('bathsMax')}
                                          />
                                        </div>
                                      </>
                                    )
                                  }}
                                </FormField>
                              </div>
                              <div className="col-span-2 sm:col-span-1">
                                <FormField
                                  error={[
                                    errors.sqftMin?.message,
                                    errors.sqftMax?.message
                                  ]}>
                                  {(props) => {
                                    const minProps = errors.sqftMin ? props : {}
                                    const maxProps = errors.sqftMax ? props : {}

                                    return (
                                      <>
                                        <Label htmlFor="sqftMin">Sqft</Label>
                                        <div className="mt-1 flex items-center space-x-2">
                                          <Input
                                            {...minProps}
                                            {...register('sqftMin')}
                                            id="sqftMin"
                                          />
                                          <div>-</div>
                                          <Input
                                            {...maxProps}
                                            {...register('sqftMax')}
                                          />
                                        </div>
                                      </>
                                    )
                                  }}
                                </FormField>
                              </div>
                              <div className="col-span-2 sm:col-span-1">
                                <FormField
                                  error={[
                                    errors.lotsizeMin?.message,
                                    errors.lotsizeMax?.message
                                  ]}>
                                  {(props) => {
                                    const minProps = errors.lotsizeMin
                                      ? props
                                      : {}
                                    const maxProps = errors.lotsizeMax
                                      ? props
                                      : {}

                                    return (
                                      <>
                                        <Label htmlFor="lotsizeMin">
                                          Lot Size
                                        </Label>
                                        <div className="mt-1 flex items-center space-x-2">
                                          <Input
                                            {...minProps}
                                            {...register('lotsizeMin')}
                                            id="lotsizeMin"
                                          />
                                          <div>-</div>
                                          <Input
                                            {...maxProps}
                                            {...register('lotsizeMax')}
                                          />
                                        </div>
                                      </>
                                    )
                                  }}
                                </FormField>
                              </div>
                              <div className="col-span-2 sm:col-span-1">
                                <FormField
                                  error={[
                                    errors.priceListMin?.message,
                                    errors.priceListMax?.message
                                  ]}>
                                  {(props) => {
                                    const minProps = errors.priceListMin
                                      ? props
                                      : {}
                                    const maxProps = errors.priceListMax
                                      ? props
                                      : {}

                                    return (
                                      <>
                                        <Label htmlFor="priceListMin">
                                          List Price
                                        </Label>
                                        <div className="mt-1 flex items-center space-x-2">
                                          <Input
                                            {...minProps}
                                            {...register('priceListMin')}
                                            id="priceListMin"
                                          />
                                          <div>-</div>
                                          <Input
                                            {...maxProps}
                                            {...register('priceListMax')}
                                          />
                                        </div>
                                      </>
                                    )
                                  }}
                                </FormField>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </Disclosure>
                      </div>
                      <div className="space-y-2">
                        <p className="text-xs text-gray-500">
                          This method automatically brings back listings closest
                          to the Subject Property.
                        </p>
                        <i className="inline-block text-xs text-gray-400">
                          Tip: Get more listings than you need - you can filter
                          out listings that are not good comps in the next step.
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button size="lg">
              {isEditing ? 'Update Listings' : 'Fetch Listings'}
            </Button>
          </div>
        </main>
      </Wrapper>
    </form>
  )
}
